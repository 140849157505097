import { Component } from 'vue-property-decorator';
import Group from '../../../models/Group';
import { groupHelper, $router, teamHelper, roleHelper, ecosystemHelper } from '@/main';
import jQuery from 'jquery';
import BaseGroupPage from './components/BaseGroupPage';
import { periodModule } from '@/store/modules/period';

@Component
export default class GroupDetailComponent extends BaseGroupPage {
    public group: Group;
    public section: string;
    public currentTabComponent: string = '';
    public editMode: boolean = false;
    public currentTab: string;
    public tabs: string[] = ['workspace', 'members', 'overview', 'income', 'expense', 'transfers', 'ambitions', 'insights', 'relations', 'settings'];

    public async created() {
        await this.checkPeriodParam();
        await this.loadTeamFinanceSettings();
        await this.loadGroupFinanceSettings();

        const groupId = parseInt(this.$route.params.groupId);
        const group = await groupHelper.getGroup(groupId);
        Object.assign(this.group, group);

        if (!this.group || this.group.groupId === 0) {
            const params = this.$route.params;
            params.tab = 'group-overview';
            await this.$router.push({ name: 'team-finances', params });
        }

        this.currentTab = this.$route.params.tab;
        if (this.currentTab === 'transfer-requests') {
            this.currentTab = 'transfers';
        }

        this.section = this.$route.meta.section;
        if (!this.tabs.indexOf(this.currentTab)) {
            this.currentTab = this.section === 'finances' ? 'overview' : 'workspace';
        }
        this.changeTab(this.currentTab);
    }

    public changeTab(tab: string): void {
        if (!this.group) {
            return;
        }

        let name = 'group-directory-';
        if (this.section === 'finances') {
            name = 'group-finance-';
        }

        if (tab !== this.$route.params.tab) {
            const params = this.$route.params;
            params.tab = tab;
            if (this.section === 'finances') {
                // tslint:disable: no-floating-promises
                $router.push({ name: 'team-finances-group', params, query: this.$route.query });
            } else {
                $router.push({ name: 'team-directory-group', params, query: this.$route.query });
            }
        } else {
            this.doActiveClass(tab);
            this.currentTabComponent = name + tab;
        }
    }

    public changeSection(section) {
        const params = this.$route.params;
        this.section = section;

        if (this.section === 'finances') {
            params.tab = 'overview';
            params.period = periodModule.selectedPeriod;
        } else {
            params.tab = 'workspace';
        }

        if (this.currentTab === 'settings') {
            params.tab = this.currentTab;
        }

        params.period = periodModule.selectedPeriod;

        $router.push({ name: `team-${this.section}-group`, params });
    }

    public doActiveClass(tab: string) {
        this.$nextTick(() => {
            jQuery('.' + tab).addClass('active');
        });
    }

    get hideAmbitions() {
        return !roleHelper.checkAccess(this.groupFinanceSettings.privacyLevel, this.group);
    }

    get hideInsights() {
        return !roleHelper.checkAccess(this.groupFinanceSettings.privacyLevel, this.group);
    }

    public edit() {
        this.editMode = true;
    }

    public cancel() {
        this.editMode = false;
    }

    public async saveGroup() {
        const self = this;
        this.showPending('SAVE_GROUP_PENDING');

        const group = await groupHelper.createOrUpdateGroup(this.group);
        if (!group || group.groupId === 0) {
            return this.clearAndShowError('SAVE_GROUP_FAILED', null);
        }

        this.editMode = false;
        if (this.group.groupKey !== group.groupKey) {
            this.clearAndShowSuccess('SAVE_GROUP_SUCCESS');
            setTimeout(async () => {
                await $router.push({
                    name: self.$route.name,
                    params: {
                        ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                        ecosystemKey: ecosystemHelper.currentEcosystem.key,
                        teamId: teamHelper.currentTeam.id.toString(),
                        teamKey: teamHelper.currentTeam.key,
                        groupKey: group.groupKey,
                        groupId: group.groupId.toString(),
                    },
                });
            }, 1500);
        } else {
            this.clearAndShowSuccess('SAVE_GROUP_SUCCESS');
        }
    }
}
