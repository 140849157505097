import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import General from './general.vue';
import TransferRequestSent from './transfer.vue';
import TransferRequestRejected from './transfer.vue';
import TransferRequestAccepted from './transfer.vue';
import Notification from './models/notification';
import { INotificationsService } from './models/Interface/INotifcationService';

@Component({
    components: {
        General,
        TransferRequestSent,
        TransferRequestRejected,
        TransferRequestAccepted,
    },
})
export default class ItemNotificationsComponent extends Vue {
    @Prop({ default: () => null }) public notification: Notification;
    @Prop() public notificationService: INotificationsService;

    private availableNotificationTypes = ['transfer-request-sent', 'transfer-request-rejected', 'transfers-request-accepted'];

    public mounted() {
        this.$off('delete-notification');
        this.$on('delete-notification', this.deleteNotification);
    }

    public getType() {
        if (this.notification.notificationTemplate) {
            const template = this.availableNotificationTypes.find((x) => x === this.notification.notificationTemplate);
            if (template) {
                return template;
            }
        }

        return 'general';
    }

    public deleteNotification(notification: Notification) {
        this.$emit('delete', notification);
    }
}
