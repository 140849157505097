export default class Enviroment {
    public name: string = '';

    public identityEndpoint: string = '';
    public directoryEndpoint: string = '';
    public insightsEndpoint: string = '';
    public financeEndpoint: string = '';
    public notificationsEndpoint: string = '';
    public portalUrl: string = '';
    public instrumentationKey: string = '';

    public enableDebug: boolean = true;

    constructor(object: any) {
        Object.assign(this, object);
    }
}
