import Group from '../Group';
import Team from '../Team';
import Ledger from './Ledger';

export class IntellectualPropertyLimitation {
    public intellectualPropertyLimitationId: number = null;
    public name: string = '';
}

// tslint:disable max-classes-per-file
export class RelationSettings {
    public current: boolean = false;
    public inherited: boolean = false;
    public intellectualPropertyLimitation: any = new IntellectualPropertyLimitation();
    public salesLedger: Ledger = new Ledger();
    public purchaseLedger: Ledger = new Ledger();
}

// tslint:disable max-classes-per-file
export default class Relation {
    public relationId: number = null;

    public originLegalEntityReference: string = null;
    public originTeam: Team;
    public originGroup: Group;

    public destinationLegalEntityReference: string = null;
    public destinationTeam: Team;
    public destinationGroup: Group;

    public intellectualPropertyLimitation: number;
    public salesLedger: number;
    public purchaseLedger: number;

    public relationSettings: RelationSettings[] = [];

    /**
     *
     */
    constructor(obj?: Partial<Relation>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
