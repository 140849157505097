import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';
import { $router, ecosystemHelper, periodService, roleHelper, teamHelper } from '@/main';
import Period from '@/models/Period';
import moment from 'moment';
import { profileModule } from './profile';

@Module
class PeriodModule extends VuexModule {
    public failed: boolean = false;
    public setupIsDone: boolean = false;

    private _periods: Period[] = [];
    private _allPeriods: Period[] = [];

    private _selectedPeriod: string = moment().format('YYYY-MM');
    private _selectedPeriodObject: Period = null;

    public get periods() {
        return this._periods;
    }

    public get allPeriods() {
        return this._allPeriods;
    }

    public get selectedPeriod() {
        return this._selectedPeriod;
    }

    public get selectedPeriodObject() {
        return this._selectedPeriodObject;
    }

    @Action
    public async init() {
        const team = teamHelper.currentTeam;
        const periods = await periodService.getPeriods(team.id);

        let availablePeriods = periods.filter((x) => x.periodId >= team.accessFrom || roleHelper.isAccountant());
        if (team.accessUntil) {
            availablePeriods = availablePeriods.filter((x) => x.periodId <= team.accessUntil || roleHelper.isAccountant());
        }

        this.SET_PERIODS(availablePeriods);
        this.SET_ALL_PERIODS(periods);

        let periodString = this.retreivePeriodFromUrl();
        let period = this._periods.find((p) => {
            return moment(p.startDate, 'YYYY-MM-DD').format('YYYY-MM') === periodString;
        });

        const ecosystemMembership = profileModule.brightProfile.ecosystemMemberships.find((x) => x.id === ecosystemHelper.currentEcosystem.id);
        if (period && period.periodId < ecosystemMembership.periodJoined) {
            period = this._periods.find((x) => x.periodId === ecosystemMembership.periodJoined);
            periodString = period.startDate;
        }

        const date = moment(periodString, 'YYYY-MM');
        if (periodString && date.isValid()) {
            this.SET_SELECTEDPERIOD(periodString);
        } else {
            this.SET_SELECTEDPERIODOBJECT(this.getFirstPeriodAfterLastClosedPeriod(this._periods));
            if (this._selectedPeriodObject) {
                this.SET_SELECTEDPERIOD(moment(this._selectedPeriodObject.startDate, 'YYYY-MM-DD').format('YYYY-MM'));
            }
        }

        this.SET_SETUPISDONE(true);
    }

    @Action
    public async reloadPeriods() {
        const periods = await periodService.getPeriods(teamHelper.currentTeam.id);
        this.SET_PERIODS(periods);
        this.SET_SELECTEDPERIODOBJECT();
    }

    @Mutation
    public SET_SELECTEDPERIOD(dateString: string) {
        const period = this._periods.find((p) => {
            return moment(p.startDate, 'YYYY-MM-DD').format('YYYY-MM') === dateString;
        });

        if (period) {
            this._selectedPeriod = dateString;
            this._selectedPeriodObject = period;
        } else {
            this._selectedPeriodObject = this.getFirstPeriodAfterLastClosedPeriod(this._periods);
            this._selectedPeriod = moment(this._selectedPeriodObject.startDate, 'YYYY-MM-DD').format('YYYY-MM');
        }
    }

    @Mutation
    public SET_SELECTEDPERIODOBJECT(selectedPeriodObject?: Period) {
        if (this._periods != null && selectedPeriodObject) {
            this._selectedPeriodObject = selectedPeriodObject;
            this._selectedPeriod = moment(selectedPeriodObject.startDate, 'YYYY-MM-DD').format('YYYY-MM');
        }

        if (this._periods != null) {
            this._selectedPeriodObject = this._periods.find((period) => {
                return moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM') === this._selectedPeriod;
            });
        }
    }

    @Mutation
    public SET_PERIODS(periods: Period[]) {
        this._periods = periods;
    }

    @Mutation
    public SET_ALL_PERIODS(periods: Period[]) {
        this._allPeriods = periods;
    }

    @Mutation
    public SET_SETUPISDONE(bool: boolean) {
        this.setupIsDone = bool;
    }

    public retreivePeriodFromUrl() {
        const path = window.location.pathname;
        const search = window.location.search;
        let route = $router.resolve(path);

        if (search) {
            const params = new URLSearchParams(window.location.search);
            const redirectUrl = params.get('redirectUrl');
            if (redirectUrl) {
                route = $router.resolve(redirectUrl);
            }
        }

        if (route && route.resolved && route.resolved.params.period) {
            return route.resolved.params.period;
        }

        return null;
    }

    public getDateString(period: Period) {
        return moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM');
    }

    private getFirstPeriodAfterLastClosedPeriod(periods: Period[]): Period {
        const ecosystemMembership = profileModule.brightProfile.ecosystemMemberships.find((x) => x.id === ecosystemHelper.currentEcosystem.id);
        const openPeriods = periods.filter((period) => !period.closed && period.periodId >= ecosystemMembership.periodJoined);

        return openPeriods[openPeriods.length - 1];
    }
}

export const periodModule = new PeriodModule({ store, name: 'period' });
