import BaseGroupPage from './BaseGroupPage';
import { Component } from 'vue-property-decorator';
import { roleHelper, transferService, periodFilterHelper, loginHelper, teamHelper, teamService } from '@/main';
import Transfer from '@/models/Transfer';
import TransferRequest from '@/models/Finance/TransferRequest';
import Vue from 'vue';
import moment from 'moment';
import to from 'await-to-js';
import { Person } from '@/models/Person';
import { TransferType } from '@/models/TransferType';
import { Member } from '@/models/Member';
import { periodModule } from '@/store/modules/period';
import { profileModule } from '@/store/modules/profile';

@Component
export default class GroupDetailTransfers extends BaseGroupPage {
    public transferRequests: TransferRequest[] = [];
    public allTransferRequests: TransferRequest[] = [];
    public allIncomingTransferRequests: TransferRequest[] = [];
    public allOutgoingTransferRequests: TransferRequest[] = [];
    public transferRequest: TransferRequest = new TransferRequest();
    public transfers: Transfer[] = [];
    public transferRequestColumns: any[] = [
        { field: 'amount', title: 'Amount', cell: this.formatImportedInvoiceAmountValue, width: 100 },
        // { cell: this.renderDirection, title: '', editable: false, width: 60 },
        { cell: this.renderRequestMaker, title: 'Receiver', editable: false, width: 250 },
        { title: 'Description', field: 'transfer.reason' },
        { field: 'status', title: 'Status', width: 250 },
        { cell: this.renderCreatedDate, title: 'Date', width: 120 },
        { cell: this.renderTransferRequestActions, title: 'Actions', width: 120 },
    ];
    public isGroupLead: boolean;

    public selectedStatus: any = null;
    public refreshModal: number = 0;

    public transfer: Transfer = new Transfer();
    public transferGridUpdate: number = 0;
    public teamId: number = null;
    public transferModalType: TransferType = TransferType.GroupToGroup;

    get transferTitle() {
        return `Make a transfer from ${this.group.name}`;
    }

    public async mounted() {
        this.members = this.group.members;
        this.isGroupLead = roleHelper.isGroupLead(this.group);

        const transferRequests = await transferService.getTransfersRequests(this.group.groupId);
        this.allTransferRequests = this.transferRequests = transferRequests.filter((x) => x.transfer && x.recipients.length > 0);

        this.teamId = teamHelper.getTeamId();
        this.teams = await teamService.getTeams();

        await this.getTransfers();

        this.isLoading = false;

        if (this.$route.query && this.$route.query['transfer-request']) {
            this.transferRequest = transferRequests.find((x) => x.transferRequestId === parseInt(this.$route.query['transfer-request'] as string));

            if (this.transferRequest) {
                Vue.nextTick(() => this.openTransferRequestModal());
            }
        }
    }

    public async getTransfers() {
        const [err, response] = await to(transferService.getTransfers(this.teamId, this.group.groupId, null, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse('Failed to load transfers');
            return [];
        }

        return (this.transfers = response.filter((x) => x.transferType === 'GroupToGroup'));
    }

    public async reloadTransfers() {
        this.isLoading = true;
        const tranferRequestsReloaded = await transferService.getTransfersRequests(this.group.groupId);
        this.allTransferRequests = this.transferRequests = tranferRequestsReloaded.filter((x) => x.transfer && x.recipients.length > 0);

        this.isLoading = false;
    }

    public async reloadData() {
        this.isLoading = true;

        await this.getTransfers();

        this.isLoading = false;
    }

    public async openTransferRequest(item) {
        if (periodFilterHelper.isCurrentPeriodClosed()) {
            return;
        }

        await this.openRequest(item.dataItem);
    }

    public async openTransfer(item) {
        if (periodFilterHelper.isCurrentPeriodClosed()) {
            return;
        }
        await this.editTransfer(item.dataItem);
    }

    public openTransferRequestModal(resetTransferRequest?: boolean) {
        if (resetTransferRequest) {
            this.transferRequest = null;
        }
        this.$refs.transferRequestModal.show();
    }

    public async openTransferModal() {
        await this.$refs.newTransferModal.init();
    }

    get incomingTransferRequests() {
        return this.transferRequests.filter((x) => x.direction === 'Receiving');
    }

    get outgoingTransferRequests() {
        return this.transferRequests.filter((x) => x.direction === 'Sending');
    }

    get getActionLabel() {
        return this.incomingTransferRequests.filter((x) => x.status === 'New').length > 0
            ? '<span class="">(action required)</span>'
            : '<span class="">(you\'re all done!)</span>';
    }

    get getActionOutgoingLabel() {
        return this.outgoingTransferRequests.filter((x) => x.status === 'New').length > 0 ? '<span class="">(waiting on their response)</span>' : '';
    }

    public statusChangedEvent(transferRequestStatusChangedEvent) {
        const request = this.transferRequests.find((x) => x.transferRequestId === transferRequestStatusChangedEvent.transferRequestId);
        request.status = transferRequestStatusChangedEvent.accepted ? 'Fulfilled' : 'Rejected';

        this.$refs.transferRequestModal.hide();
        this.transferRequest = new TransferRequest();
    }

    public statusChanged() {
        if (this.selectedStatus === null) {
            return (this.transferRequests = this.allTransferRequests);
        }

        return (this.transferRequests = this.allTransferRequests.filter((x) => x.status === this.selectedStatus));
    }

    public renderRequestMaker(h, _, row) {
        let team = null;
        let group = null;

        if (row.dataItem.direction !== 'Receiving') {
            team = this.teams.find((x) => x.teamId === row.dataItem.transfer.senderTeam.teamId);
            group = team.groups.find((x) => x.groupId === row.dataItem.receiverGroup);
        } else {
            team = this.teams.find((x) => x.teamId === row.dataItem.transfer.receiverTeam.teamId);
            group = team.groups.find((x) => x.groupId === row.dataItem.senderGroup);
        }

        return h('td', `${team.name} - ${group.name}`);
    }

    public formatImportedInvoiceAmountValue(h, _, row) {
        const props = { value: row.dataItem.transfer.amount };
        return h(Vue.component('number-formatter'), { props });
    }

    public async rejectRequest(item: TransferRequest) {
        await this.$refs.transferRequestModal.rejectRequest(item.transferRequestId);
    }

    public async withdrawRequest(item: TransferRequest) {
        await this.$refs.transferRequestModal.withdrawRequest(item.transferRequestId);
    }

    public async acceptRequest(item: TransferRequest) {
        await this.$refs.transferRequestModal.acceptRequest(item.transferRequestId);
    }

    public async openRequest(item: TransferRequest) {
        this.transferRequest = item;
        this.$refs.transferRequestModal.show();
        Vue.nextTick(async () => {
            await this.$refs.transferRequestModal.initRequest();
        });
    }

    public renderCreatedDate(h, _, row) {
        return h('td', [moment(row.dataItem.dateCreated, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    public isRequestReceiver(transferRequest: TransferRequest): boolean {
        return transferRequest && transferRequest.receiverGroup === this.group.groupId;
    }

    public isRequestSender(transferRequest: TransferRequest): boolean {
        return transferRequest && transferRequest.senderGroup === this.group.groupId;
    }

    public renderTransferRequestActions(h, _, row) {
        let actions = [];
        const userId = profileModule.brightProfile.personId;
        const initiator = row.dataItem && typeof row.dataItem.initiator !== 'undefined' ? parseInt(row.dataItem.initiator) : 0;
        if (
            this.isRequestSender(row.dataItem) &&
            row.dataItem.status === 'New' &&
            (initiator === userId || roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee() || this.isGroupLead)
        ) {
            actions = [
                { title: 'Withdraw', function: this.withdrawRequest, disabled: periodFilterHelper.isCurrentPeriodClosed() },
                { title: 'Open', function: this.openRequest, disabled: periodFilterHelper.isCurrentPeriodClosed() },
            ];
        } else if (
            this.isRequestReceiver(row.dataItem) &&
            row.dataItem.status === 'New' &&
            (initiator === userId ||
                roleHelper.isSiteAdmin() ||
                roleHelper.isFinanceEmployee() ||
                this.isGroupLead ||
                row.dataItem.recipients.find((x) => x.personId === userId))
        ) {
            actions = [
                { title: 'Reject', function: this.rejectRequest, disabled: periodFilterHelper.isCurrentPeriodClosed() },
                { title: 'Open', function: this.openRequest, disabled: periodFilterHelper.isCurrentPeriodClosed() },
                { title: 'Accept', function: this.acceptRequest, disabled: periodFilterHelper.isCurrentPeriodClosed() },
            ];
        } else {
            return h('td');
        }

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderDirection(h, _, row) {
        const props = {
            action: () => this.openRequest(row.dataItem),
            isIncoming: row.dataItem.direction === 'Receiving',
        };

        return h(Vue.component('grid-transfer-direction'), { props });
    }

    public renderMemberInitiator(h, _, row) {
        let member = row.dataItem.initiatorPerson;

        if (!member) {
            member = this.members.find((m: Member) => m.memberId === row.dataItem.initiator);
        }

        if (!member) {
            member = new Person();
            member.firstName = 'Not available';
        }

        return this.renderMember(h, member);
    }

    public renderActions(h, _, row: any): any {
        let actions = [];
        const userId = loginHelper.getUser().personId;
        const initiator = row.dataItem && typeof row.dataItem.initiator !== 'undefined' ? parseInt(row.dataItem.initiator) : 0;

        if (initiator === userId || roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) {
            actions = [
                { title: 'Edit', function: this.editTransfer, disabled: periodFilterHelper.isCurrentPeriodClosed() },
                { title: 'Delete', function: this.removeTransfer, disabled: periodFilterHelper.isCurrentPeriodClosed() },
            ];
        }

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public async editTransfer(item) {
        this.$refs.newTransferModal.show();
        Vue.nextTick(async () => {
            this.$refs.newTransferModal.editMode = true;
            await this.$refs.newTransferModal.initTransfer(item);
        });
    }

    public async removeTransfer(item) {
        await transferService.deleteTransfer(item.transferId);

        const transfer = this.transfers.findIndex((t: Transfer) => {
            return t.transferId === item.transferId;
        });

        this.transfers.splice(transfer, 1);
        this.transferGridUpdate++;
    }
}
