import { AxiosPromise } from 'axios';
import { AxiosHelper } from '../helpers/axiosHelper';
import { InvoiceType } from '../models/InvoiceType';
import { BaseService } from './baseService';
import DistributedInvoice from '../models/DistributedInvoice';
import Vue from 'vue';
import TeamRevenueResult from '@/models/TeamRevenueResult';
import GroupAlias from '@/models/GroupAlias';
import to from 'await-to-js';
import { teamsModule } from '@/store/modules/teams';
import CrossLegalTransaction from '@/models/Finance/CrossLegalTransaction';
import { Invoice } from '@/models/Invoice';

export class FinanceService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getExampleCsv(team: string) {
        const config = {
            headers: {
                'Content-type': 'text/csv',
                // tslint:disable-next-line: object-literal-key-quotes
                Accept: 'text/csv',
            },
        };

        return this.get(`${this.endpoint}invoices/new?teamId=:team`, [['team', team]], config);
    }

    public importInvoice(file: DistributedInvoice[], team: number, group: number): AxiosPromise<DistributedInvoice[]> {
        file.forEach((x) => {
            x.invoice.amount = !isNaN(x.invoice.amount) ? parseFloat(x.invoice.amount.toString()) : x.invoice.amount;
            x.distributions.forEach((y) => (y.amount = !isNaN(y.amount) ? parseFloat(y.amount.toString()) : y.amount));
        });

        if (group) {
            return this.post<DistributedInvoice[]>(`${this.endpoint}invoices?teamId=:team&groupId=:?group`, file, [
                ['team', team],
                ['group', group],
            ]);
        }
        return this.post<DistributedInvoice[]>(`${this.endpoint}invoices?teamId=:team`, file, [['team', team]]);
    }

    public async getInvoices(
        teamId: number,
        groupId: number,
        type: InvoiceType = InvoiceType.Unknown,
        personId?: number,
        period: string = '',
    ): Promise<DistributedInvoice[]> {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        if (groupId) {
            const [errWithGroup, responseWithGroup] = await to(
                this.get<DistributedInvoice[]>(
                    `${this.endpoint}invoices?teamId=:team&groupId=:group&invoiceType=:invoiceType&personId=:?personId`,
                    [
                        ['team', teamId],
                        ['group', groupId],
                        ['invoiceType', type],
                        ['personId', personId],
                    ],
                    config,
                ),
            );

            if (errWithGroup) {
                // console.error(errWithGroup);
                return [];
            }

            return responseWithGroup.data;
        }

        const [err, response] = await to(
            this.get<DistributedInvoice[]>(
                `${this.endpoint}invoices?teamId=:team&invoiceType=:invoiceType&personId=:?personId`,
                [
                    ['team', teamId],
                    ['invoiceType', type],
                    ['personId', personId],
                ],
                config,
            ),
        );

        if (err) {
            // console.error(err);
            return [];
        }

        return response.data;
    }

    public getInvoice(invoiceId: number, groupId?: number): AxiosPromise<DistributedInvoice> {
        if (groupId) {
            return this.get<DistributedInvoice>(`${this.endpoint}invoices/${invoiceId}?groupId=:group`, [['group', groupId]]);
        }

        return this.get<DistributedInvoice>(`${this.endpoint}invoices/${invoiceId}`);
    }

    public getInvoiceDetails(invoiceId: number, groupId: number, accountId: number): AxiosPromise<DistributedInvoice> {
        return this.get<DistributedInvoice>(`${this.endpoint}invoices/${invoiceId}/details?group_id=:group&account_id=:accountId`, [
            ['group', groupId],
            ['accountId', accountId],
        ]);
    }

    public saveDistribution(distributedInvoice: DistributedInvoice, teamId: number, groupId?: number): AxiosPromise {
        return this.post<DistributedInvoice[]>(
            `${this.endpoint}invoices?teamId=:team&groupId=:?group`,
            [distributedInvoice],
            [
                ['team', teamId],
                ['group', groupId],
            ],
        );
    }

    public saveDistributions(distributedInvoices: DistributedInvoice[], teamId: number, groupId?: number): AxiosPromise {
        if (!groupId || groupId === 0) {
            return this.post<DistributedInvoice[]>(`${this.endpoint}invoices?teamId=:team`, distributedInvoices, [['team', teamId]]);
        }

        return this.post<DistributedInvoice[]>(`${this.endpoint}invoices?teamId=:team&groupId=:?group`, distributedInvoices, [
            ['team', teamId],
            ['group', groupId],
        ]);
    }

    public deleteInvoice(invoiceId: number): any {
        return this.delete(`${this.endpoint}invoices/:invoiceId`, [['invoiceId', invoiceId]]);
    }

    public setInvoicesNotRercurring(list: any) {
        return this.post(`${this.endpoint}/invoices/set-recurrence`, { invoiceStates: list });
    }

    public setInvoicesLegalEntityReference(list: any[]): Promise<unknown> {
        return this.post(`${this.endpoint}/invoices/set-legal-entity-reference`, { teamId: teamsModule.current.teamId, invoiceStates: list });
    }

    public setInvoiceAmortization(invoiceId: number, accountId: number, amortizationAmount: string) {
        return this.post(`${this.endpoint}/invoices/:invoiceId/set-amortization`, { accountId, amortizationAmount }, [['invoiceId', invoiceId]]);
    }

    public getGroupFinancialActiviy(teamId: number, groupId: number, period: string) {
        return this.get(`${this.endpoint}financial-activity?teamId=:team&groupId=:group&period=:period`, [
            ['team', teamId],
            ['period', period],
            ['group', groupId],
        ]);
    }

    public getPersonFinancialActityInTeam(teamId: number, personId: number, period: string) {
        return this.get(`${this.endpoint}financial-activity?teamId=:team&personId=:person&period=:period`, [
            ['team', teamId],
            ['period', period],
            ['person', personId],
        ]);
    }

    public getPersonFinancialActiviy(teamId: number, groupId: number, personId: number, period: string) {
        return this.get(`${this.endpoint}financial-activity?teamId=:team&personId=:person&groupId=:group&period=:period`, [
            ['team', teamId],
            ['period', period],
            ['person', personId],
            ['group', groupId],
        ]);
    }

    public getMyFinancialActiviy(groupId: number, period: string) {
        return this.get(`${this.endpoint}me/financial-group-activity?teamId=:team&groupId=:group&period=:period`, [
            ['period', period],
            ['group', groupId],
        ]);
    }

    public getEcosystemRevenue(period: string): AxiosPromise<TeamRevenueResult[]> {
        return this.get(`${this.endpoint}intra-revenue/teams?period=:period`, [['period', period]]);
    }

    public getTeamRevenue(period: string, team: number): AxiosPromise<TeamRevenueResult[]> {
        const config = {
            headers: {
                'x-period': period,
            },
        };

        return this.get(
            `${this.endpoint}intra-revenue/groups?teamId=:team`,
            [
                ['period', period],
                ['team', team],
            ],
            config,
        );
    }

    public upsertGroupAliases(groupId: number, aliases: GroupAlias) {
        return this.put(`${this.endpoint}group-aliases/:groupId`, aliases, [['groupId', groupId]]);
    }

    public saveCollaborationMode(groupId: number, type: string) {
        return this.post(`${this.endpoint}groups/:groupId/set-collaboration-mode`, { CollaborationMode: type }, [['groupId', groupId]]);
    }

    public getGroupAliases(groupId: number) {
        return this.get(`${this.endpoint}group-aliases/:groupId`, [['groupId', groupId]]);
    }

    public async getCrossLegalTransactions(matchId?: string): Promise<CrossLegalTransaction[]> {
        let url = `${this.endpoint}cross-legal-transactions`;

        if (matchId) {
            url = `${url}?matchId=${matchId}`;
        }

        const [err, response] = await to(this.get(url));
        if (err) {
            this.clearAndShowError('CROSS_LEGAL_TRANSACTIONS_FAILED', err);
            return [];
        }

        return response.data.map((x) => new CrossLegalTransaction(x));
    }

    public async searchInvoices(
        fromFilterMonth: any,
        toFilterMonth: any,
        teamId?: number,
        selectedLegalEntityRef?: string,
        invoiceType: InvoiceType = InvoiceType.Income,
    ) {
        const config = {
            headers: {
                'x-startPeriod': fromFilterMonth,
                'x-endPeriod': toFilterMonth,
            },
        };

        let url = `${this.endpoint}invoices/search?invoice_type=${invoiceType}`;

        if (teamId) {
            url = `${url}&team_id=${teamId}`;
        }

        if (selectedLegalEntityRef) {
            url = `${url}&legal_entity_reference=${selectedLegalEntityRef}`;
        }

        const [err, response] = await to(this.get<Invoice[]>(`${url}`, null, config));

        if (err) {
            // console.error(err);
            return [];
        }

        return response.data.map((x) => new Invoice(x));
    }
}
