import { render, staticRenderFns } from "./are-you-sure-modal.vue?vue&type=template&id=44fa78b0&"
import script from "./are-you-sure-modal.ts?vue&type=script&lang=js&"
export * from "./are-you-sure-modal.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports