import { relationService } from '@/main';
import Relation, { IntellectualPropertyLimitation } from '@/models/Finance/Relation';
import PageRender from '@/models/PageRender';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class TeamFinanceRelations extends PageRender {
    public columns: any = [
        { title: 'Legal entity', field: 'originLegalEntityReference' },
        { title: 'Team', field: 'originTeam', cell: this.renderTeamName },
        { title: 'Group', field: 'originTeam', cell: this.renderGroupName },
        { title: 'Legal entity', field: 'destinationLegalEntityReference' },
        { title: 'Team', field: 'originTeam', cell: this.renderDestinationTeamName },
        { title: 'Group', field: 'originTeam', cell: this.renderDestinationGroupName },
        { title: 'Settings', cell: this.renderSettingsLink },
        { title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public $refs!: {
        relationModal: any;
    };

    public onlyWithoutSettings: boolean = true;
    public includeDeletedRelations: boolean = false;
    public relations: Relation[] = [];
    public allRelations: Relation[] = [];
    public intellectualPropertyLimitations: IntellectualPropertyLimitation[] = [];
    public relationsGridKey: number = 0;

    public async mounted() {
        await this.loadRelations(this.includeDeletedRelations);
        this.intellectualPropertyLimitations = (await relationService.getRelationProperties()).data.intellectualPropertyLimitations;
        this.isLoading = false;
    }

    public reloadRelations() {
        this.relations = this.filterRelations();
        this.relationsGridKey++;
    }

    public async loadRelations(includeDeleted: boolean) {
        this.allRelations = await relationService.getRelations(includeDeleted);

        console.log(this.allRelations);

        this.relations = this.filterRelations();
    }

    public async reloadWithDeletedRelations() {
        await this.loadRelations(this.includeDeletedRelations);
        this.reloadRelations();
    }

    private filterRelations() {
        if (this.onlyWithoutSettings) {
            return this.allRelations.filter((x) => x.relationSettings.length === 0);
        }
        return this.allRelations;
    }

    private renderTeamName(h, _, row) {
        if (row.dataItem.originTeam && row.dataItem.destinationTeam && row.dataItem.originTeam.teamId === row.dataItem.destinationTeam.teamId) {
            return h('td');
        }

        return h('td', row.dataItem.originTeam ? row.dataItem.originTeam.name : '');
    }

    private renderGroupName(h, _, row) {
        return h('td', row.dataItem.originGroup ? row.dataItem.originGroup.name : '');
    }

    private renderDestinationTeamName(h, _, row) {
        return h('td', row.dataItem.destinationTeam ? row.dataItem.destinationTeam.name : '');
    }

    private renderDestinationGroupName(h, _, row) {
        return h('td', row.dataItem.destinationGroup ? row.dataItem.destinationGroup.name : '');
    }

    private renderSettingsLink(h, _, row) {
        const props = { relation: row.dataItem, intellectualPropertyLimitations: this.intellectualPropertyLimitations };
        return h(Vue.component('grid-relation-settings'), { props });
    }

    private async deleteRelation(item: Relation): Promise<void> {
        const success = await relationService.deleteRelation(item.relationId);

        if (success) {
            this.clearAndShowSuccess('RELATION_DELETED_SUCCESS');
        } else {
            this.clearAndShowError('RELATION_DELETED_FAILED');
        }

        await this.loadRelations(this.includeDeletedRelations);
        this.reloadRelations();
    }

    private renderActions(h, _, row: { dataItem: Relation }): any {
        const actions = [{ title: 'Delete relation', function: this.deleteRelation }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
