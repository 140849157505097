import Relation, { RelationSettings } from '@/models/Finance/Relation';
import to from 'await-to-js';
import Vue from 'vue';
import { BaseService } from './baseService';

export default class RelationService extends BaseService {
    private endpoint = `${Vue.$env().financeEndpoint}/relations`;

    public getRelationProperties() {
        return this.get(`${Vue.$env().financeEndpoint}relationship-properties`);
    }

    public async getRelations(
        includeDeleted: boolean, teamId?: number, groupId?: number): Promise<Relation[]> {
        let url = `${this.endpoint}?includeDeleted=${includeDeleted}`;

        if (teamId) {
            url += `&teamId=${teamId}`;
        }

        if (groupId) {
            url += `&groupId=${groupId}`;
        }

        const [err, response] = await to(this.get(url));
        if (err) {
            this.clearAndShowError('FAILED_GETTING_RELATIONS', err);
            return [];
        }

        return response.data.map((x) => new Relation(x));
    }

    public async deleteRelation(relation: number): Promise<boolean> {
        const [err] = await to(this.delete(`${this.endpoint}/:relation`, [['relation', relation]]));
        return !err;
    }

    public createOrUpdateSettings(relation: number, settings: RelationSettings) {
        const model = {
            intellectualPropertyLimitation: settings.intellectualPropertyLimitation.intellectualPropertyLimitationId,
            salesLedger: settings.salesLedger.ledgerId,
            purchaseLedger: settings.purchaseLedger.ledgerId,
        };

        return this.put(`${this.endpoint}/:relation/settings`, model, [['relation', relation]]);
    }
}
