import { render, staticRenderFns } from "./translate-group.vue?vue&type=template&id=906f80e6&"
import script from "./translate-group.ts?vue&type=script&lang=js&"
export * from "./translate-group.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports