import Component from 'vue-class-component';
import { $router, eventBus, loginHelper } from '@/main';
import Period from '../../models/Period';
import moment from 'moment';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import { filtersModule } from '@/store/modules/filters';
import { profileModule } from '@/store/modules/profile';
import { periodModule } from '@/store/modules/period';
@Component
export default class PeriodFilter extends PageRender {
    public componentKey: number = 0;

    public created() {
        eventBus.$on('teamChange', this.forceRerender);
    }

    public get periods() {
        return periodModule.periods;
    }

    public selectedPeriodObject() {
        return periodModule.selectedPeriodObject;
    }

    public async selectPeriod(period: Period) {
        const dateString = this.getDateString(period);

        if (dateString) {
            periodModule.SET_SELECTEDPERIOD(dateString);

            const params = this.$route.params;
            params.period = periodModule.selectedPeriod;

            filtersModule.setStartMonth(dateString);
            filtersModule.setEndMonth(dateString);

            const [err, response] = await to(loginHelper.reloadProfile());
            if (err) {
                this.clearAndShowError('Failed to reload profile', err);
                await $router.push({ name: 'login' });
                return loginHelper.logOut();
            }

            profileModule.setBrightProfile(response);
            if (typeof params.teamId !== 'undefined' && params.teamId) {
                const hasActiveMembership = response.teamMemberships.find((x) => x.id === parseInt(params.teamId));
                if (!hasActiveMembership) {
                    params.teamKey = response.teamMemberships[0].key;
                    params.teamId = response.teamMemberships[0].id.toString();
                    this.showWarning(`This team didn't exist on ${dateString}, switched to team ${response.teamMemberships[0].name}`);
                }
            }

            await $router.push({ name: this.$route.name, params });

            this.forceRerender();
            eventBus.$emit('onPeriodChanged');
        }
    }

    public getFancyDate(period: Period) {
        if (period && period.startDate) {
            return moment(period.startDate, 'YYYY-MM-DD').format(`MMMM 'YY`);
        }
    }

    public getDateString(period: Period) {
        return moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM');
    }

    public forceRerender() {
        this.componentKey += 1;
    }
}
