import { Component } from 'vue-property-decorator';
import PageRender from '../../../models/PageRender';
import {
    groupHelper,
    roleHelper,
    memberHelper,
    connectorService,
    templateService,
    teamHelper,
    groupService,
    teamService,
    attributionService,
    ecosystemHelper,
} from '@/main';
import moment from 'moment';
import Group from '@/models/Group';
import { VueEditor } from 'vue2-editor';
import { periodModule } from '@/store/modules/period';
import Connector from '@/models/Connectors/Connector';
import { Person } from '@/models/Person';
import { Member } from '@/models/Member';
import { profileModule } from '@/store/modules/profile';
import { required, minLength } from 'vuelidate/lib/validators';
import to from 'await-to-js';
import { PrivacyLevel } from '@/models/PrivacyLevel';
import Attribution from '@/models/Attribution';
import Team from '@/models/Team';
import { AttributionType } from '@/models/AttributionType';

@Component({
    components: {
        VueEditor,
    },
    validations: {
        createGroupForm: {
            name: { required },
            purpose: { required },
            readMe: { minLength: minLength(20) },
        },
        selectMemberIds: { required, minLength: minLength(1) },
        selectedGroupLeadId: { required },
    },
} as any)
export default class TeamDirectoryAddGroupComponent extends PageRender {
    public connectors: Connector[] = [];
    public selectedConnectors: any[] = [];
    public _members: Person[] = [];
    public groups: Group[] = [];
    public selectedTemplateId: number = 0;

    public createGroupForm: Group = new Group();
    public selectedGroupLeadId: number = null;
    public selectMemberIds: number[] = [];

    public submitted: boolean = false;
    public stepIndex: number = 0;
    public energySpent: number = 0;
    public period: number = null;

    public templates: any[] = [];
    public resourceAttributions: Attribution[] = [];
    public groupAttributions: Attribution[] = [];

    public importServiceAttributionGroupId?: number = null;

    public teams: Team[] = [];

    public customControls: any = [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ];

    public roleOptions: any[] = [
        { text: 'Site Admin', value: 'SiteAdmin' },
        { text: 'Finance Employee', value: 'FinanceEmployee' },
        { text: 'Group Lead', value: 'GroupLead' },
        { text: 'Member', value: 'Member' },
    ];

    public groupFinanceSettings: any = {
        applyMissingAttributionsOnTransfer: false,
        autoDistributeIncomeToSavings: false,
        autoDistributeExpensesToSavings: false,
        autoCompensateGroupMembers: false,
        privacyLevel: PrivacyLevel.Unknown,
    };

    public async mounted() {
        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
            await this.$router.push({ name: 'not-found' });
        }

        this._members = await memberHelper.getMembers(true);
        this.connectors = await connectorService.getConnectors();

        this.groups = await groupHelper.getGroups();

        this.createGroupForm.groupLead = new Member(this._members.find((x) => x.personId === profileModule.brightProfile.personId));
        this.selectedGroupLeadId = this.createGroupForm.groupLead.memberId;
        this.selectMemberIds.push(this.selectedGroupLeadId);

        this.period = periodModule.selectedPeriodObject.periodId;

        await this.loadTemplates();
        this.teams = await teamService.getTeams();

        this.isLoading = false;
    }

    get periods() {
        return periodModule.periods.map((period) => {
            return {
                text: moment(period.startDate).format(`MMMM 'YY`),
                value: period.periodId,
            };
        });
    }

    get members() {
        let list = [];

        if (this._members && this._members.length > 0) {
            list = this._members.map((member) => {
                return {
                    text: `${member.getFullName()}${member.personId === this.selectedGroupLeadId ? ' (group lead)' : ''}`,
                    value: member.personId,
                };
            });
        }

        return list.sort((x, y) => {
            return x.value === this.selectedGroupLeadId ? -1 : y === this.selectedGroupLeadId ? 1 : 0;
        });
    }

    get memberNames() {
        return this._members
            .filter((x) => this.selectMemberIds.indexOf(x.personId) > -1)
            .map((x) => x.getFullName())
            .join(', ');
    }

    get templateName() {
        return this.templates.find((x) => x.financeGroupTemplateId === this.selectedTemplateId).name;
    }

    get connectorNames() {
        return this.connectors
            .filter((x) => this.selectMemberIds.indexOf(x.connectorId) > -1)
            .map((x) => x.name)
            .join(', ');
    }

    public valid(index: number = this.stepIndex) {
        this.clearNotifications();
        if (index === 0) {
            return !(this as any).$v.createGroupForm.$invalid && !(this as any).$v.selectedGroupLeadId.$invalid;
        } else if (index === 1) {
            return !(this as any).$v.selectMemberIds.$invalid;
        } else if (index === 2) {
            return !(this as any).$v.createGroupForm.$invalid;
        } else if (index === 3) {
            // no validation needed, connector is selected or not.
        } else if (index === 4) {
            // no validation, default no template selected
        } else if (index === 5) {
            // validate finance settings when editable
        } else if (index === 6) {
            // validate attributions settings when editable
        } else if (index === 7) {
            return !(this as any).$v.createGroupForm.$invalid || !(this as any).$v.selectMemberIds.$invalid;
        }

        return true;
    }

    get hasFinanceConnector() {
        return this.selectedConnectors.filter((x) => x === 1).length > 0;
    }

    public connectorOptions() {
        return this.connectors.map((x) => {
            return { value: x.connectorId, text: x.name };
        });
    }

    public optionListMembers(): any[] {
        const list = this._members
            .filter((x) => x.externalIdentifier)
            .map((member) => {
                return {
                    value: member.personId,
                    text: member.firstName + ' ' + (member.insertion ? member.insertion + ' ' : '') + member.lastName,
                };
            });
        return list;
    }

    public moveToTab(newTabIndex, prevTabIndex, bvEvent) {
        if (newTabIndex > this.stepIndex + 1 || (!this.valid(prevTabIndex) && newTabIndex > prevTabIndex)) {
            this.submitted = true;
            return bvEvent.preventDefault();
        }

        this.submitted = false;
    }

    public async next() {
        this.submitted = true;
        if (this.valid()) {
            this.stepIndex++;

            if (this.stepIndex === 4) {
                await this.loadTemplates();
            }

            if (this.stepIndex === 6) {
                this.teams = await teamService.getTeams();
            }
            this.submitted = false;
        }
    }

    public back() {
        this.stepIndex--;
    }

    public async finalize() {
        if (this.valid()) {
            await this.addGroup();
        }
    }

    public async loadTemplates() {
        this.templates = await templateService.getTemplates(teamHelper.currentTeam.id);
    }

    public updateMemberList(groupLeadId) {
        this.selectMemberIds = [groupLeadId];
    }

    public selectTemplate(template) {
        this.resourceAttributions = [];
        this.groupAttributions = [];

        this.selectedTemplateId = template.financeGroupTemplateId;

        this.groupFinanceSettings.applyMissingAttributionsOnTransfer = template.applyMissingAttributionsOnTransfer;
        this.groupFinanceSettings.autoDistributeIncomeToSavings = template.autoDistributeIncomeToSavings;
        this.groupFinanceSettings.autoDistributeExpensesToSavings = template.autoDistributeExpensesToSavings;
        this.groupFinanceSettings.autoCompensateGroupMembers = template.autoCompensateGroupMembers;
        this.groupFinanceSettings.privacyLevel = template.privacyLevel;

        template.attributions
            .filter((x) => x.attributionType === AttributionType.GroupAttribution)
            .forEach(async (attr) => {
                await this.addServiceAttribution(attr);
            });

        template.attributions
            .filter((x) => x.attributionType === AttributionType.GroupRemittance)
            .forEach((attr) => {
                this.addGroupAttribution(attr);
            });
    }

    public async addServiceAttribution(attribution?: Attribution) {
        const newAttribution = attribution ? attribution : new Attribution(0);
        newAttribution.targetTeamModel = this.teams.find((team) => {
            return team.teamId === (attribution ? attribution.targetTeam : teamHelper.currentTeam.id);
        });

        newAttribution.groups = await this.getTeamGroups(newAttribution.targetTeamModel);

        if (attribution) {
            newAttribution.targetGroupModel = newAttribution.groups.find((x) => x.groupId === newAttribution.targetGroup);
        }

        this.resourceAttributions.push(newAttribution);
    }

    public addGroupAttribution(attribution?: Attribution): any {
        const newAttribution = attribution ? attribution : new Attribution(0);
        this.groupAttributions.push(newAttribution);
    }

    public async getTeamGroups(team: Team) {
        const [err, response] = await to(groupService.getGroups(team.teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse(`Failed to load groups for team ${team.teamKey}`, null);
        }

        return response.data;
    }

    public async teamChange(attribution: Attribution) {
        attribution.targetGroup = null;
        attribution.groups = await this.getTeamGroups(attribution.targetTeamModel);
    }

    public deleteGroupAttribution(item: Attribution) {
        this.groupAttributions.splice(this.groupAttributions.indexOf(item), 1);
    }

    public deleteServiceAttribution(item: Attribution) {
        this.resourceAttributions.splice(this.resourceAttributions.indexOf(item), 1);
    }

    public async importServiceAttribution() {
        const self = this;

        // this.copingLoading = true;
        const currentTeamId = teamHelper.getTeamId(teamHelper.currentTeamId);

        await attributionService
            .getAttributions(AttributionType.GroupAttribution, currentTeamId, periodModule.selectedPeriod, this.importServiceAttributionGroupId)
            .then((result) => {
                result.data.forEach(async (attribution: Attribution) => {
                    attribution.attributionId = undefined;
                    // attribution.group = self.group.groupId;
                    const foundTeam = self.teams.find((team) => team.teamId === attribution.targetTeam);
                    attribution.targetTeamModel = foundTeam;
                    const groups = (await teamService.getGroups(foundTeam.teamId, periodModule.selectedPeriod)).data;
                    attribution.targetGroupModel = groups.find((group) => group.groupId === attribution.targetGroup);
                    attribution.groups = groups;

                    self.resourceAttributions.push(attribution);
                });
            });

        // this.copingLoading = false;
    }

    public async addGroup() {
        const periodString = moment(periodModule.periods.find((x) => x.periodId === this.period).startDate, 'YYYY-MM-DD').format('YYYY-MM');
        this.showPending('CREATE_GROUP_PENDING');
        this.createGroupForm.connectors = this.selectedConnectors;
        this.createGroupForm.groupLead = new Member(this._members.find((x) => x.personId === this.selectedGroupLeadId));
        this.createGroupForm.templateId = this.selectedTemplateId > 0 ? this.selectedTemplateId : null;

        const success = await groupHelper.createOrUpdateGroup(this.createGroupForm, periodString);
        if (!success) {
            this.showFailedResponse('Failed to create group');
        }

        if (success) {
            const groups = await teamService.getGroups(teamHelper.currentTeam.id, periodString);
            const group = groups.data.find((x) => x.name === this.createGroupForm.name);

            if (this.selectMemberIds.length > 1 && group) {
                this.clearNotifications();
                this.showPending('Adding selected members to the group...');
                await groupService.addMembers(this.selectMemberIds, group.groupId, teamHelper.currentTeam.id, periodString);
            }

            this.clearNotifications();
            await this.$router.push({
                name: 'team-directory-group',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                    teamId: teamHelper.currentTeam.id.toString(),
                    teamKey: teamHelper.currentTeam.key,
                    groupId: group.groupId.toString(),
                    groupKey: group.groupKey,
                    tab: 'members',
                    period: periodModule.selectedPeriod,
                },
            });
        }
    }
}
