import GraphEdge from '@/models/Graph/GraphEdge';
import { Svg } from '@svgdotjs/svg.js';
import { Component } from 'vue-property-decorator';
import { MapView } from '../models/MapView';
import { insightService } from '@/main';
import PeriodRange from '@/models/PeriodRange';
import PageRender from '@/models/PageRender';
import MapNode from '../models/MapNode';
import InsightView from '@/models/Insights/InsightView';
import { teamsModule } from '@/store/modules/teams';
import PrdSvgCanvasVue from '../components/prd-svg-canvas.vue';
import PrdCanvasComponent from '../components/prd-svg-canvas';
import SvgRender from '../svg/SvgRender';
import SvgRenderInfoCell from '../svg/SvgRenderInfoCell';
import SvgRenderCellSpace from '../svg/SvgRenderCellSpace';
import { insightStoreModule } from '../store/map';
import MapData from '../models/MapData';
import SvgAnimate from '../svg/SvgAnimate';
@Component({
    components: {
        PrdSvgCanvas: PrdSvgCanvasVue,
    },
})
export default class CellsPage extends PageRender {
    public mapData = new MapData();
    public primarySvg: SvgRender = new SvgRender();
    public secondarySvg: SvgRenderInfoCell = new SvgRenderInfoCell();
    public canvas: Svg = null;
    public renderTiemout = null;
    public selectedId: string | number = null;
    public view: MapView = MapView.Team;
    public dates: PeriodRange = { toFilterMonth: '', fromFilterMonth: '' };
    public views: InsightView[] = [];
    public insight: InsightView = null;
    public loading = true;
    public loadingGraph = false;
    public rotate: number = 0;
    public margin: number = 100;
    public zoom: number = 1;

    public $refs!: {
        svgPrimaryCanvas: PrdCanvasComponent,
        svgSecondaryCanvas: PrdCanvasComponent,
    };

    public get incoming(): GraphEdge[] {
        return this.mapData.edges.filter((edge: GraphEdge) => edge.destination === this.selectedId);
    }

    public get outgoing(): GraphEdge[] {
        return this.mapData.edges.filter((edge: GraphEdge) => edge.origin === this.selectedId);
    }

    public get nodes(): MapNode[] {
        return [...this.mapData.nodes, ...this.mapData.external];
    }

    public get ecology() {
        return this.$route.query && this.$route.query.ecology ? this.$route.query.ecology : null;
    }

    public get teamId() {
        return this.$route.query && this.$route.query.team ? +this.$route.query.team : null;
    }

    public get activityId() {
        return this.$route.query && this.$route.query.activity ? +this.$route.query.activity : null;
    }

    public get canGoUp() {
        return Object.keys(this.$route.query).length > 1;
    }

    public async mounted() {
        this.loading = true;
        this.views = await insightService.getViews(true);
        this.insight = this.getInsight();
        await teamsModule.fetchTeamsIfNeeded();
        this.loading = false;

        this.$watch('$route.query', () => this.fetchAndDraw(false), { immediate: true, deep: true });
    }

    public nodeName(id: string | number) {
        const node = this.nodes.find((n: MapNode) => n.id === id);
        return node ? node.title : '';
    }

    public periodChanged(dates: PeriodRange) {
        this.dates = dates;
    }

    public async fetchAndDraw(refresh = false) {
        if (!this.insight) {
            return;
        }

        this.view = this.getView();
        await this.drawSecondary();
        this.selectedId = null;

        if (this.view === MapView.Ecology) {
            await this.drawEcology(refresh);
        } else {
            await this.drawCellspace(this.view, refresh);
        }
    }

    public async drawCellspace(view: MapView, refresh: boolean) {
        const callback = {
            [MapView.Team]: async () => await insightStoreModule.getTeamsInsight(this.insight, this.dates, refresh),
            [MapView.Activity]: async () => await insightStoreModule.getActivitiesInsight(this.teamId, this.dates, refresh),
            [MapView.Member]: async () => await insightStoreModule.getMembersInsight(this.activityId, this.dates, refresh),
        };

        if (!callback[view]) {
            throw new Error(`Callback for ${view} not defined`);
        }

        this.loadingGraph = true;
        const mapData = await callback[view]();
        this.mapData = mapData;
        const svg = new SvgRenderCellSpace(this.$refs.svgPrimaryCanvas.canvas);
        svg.on('select', this.onSelect);
        svg.on('navigate', this.onNavigate);
        svg.setData(mapData);
        svg.render();
        svg.fit();
        this.primarySvg = svg;
        this.loadingGraph = false;

        SvgAnimate.appear(svg.cellsGroup);
        SvgAnimate.appear(svg.extGroup);
        SvgAnimate.fadeIn(svg.edgesGroup);
    }

    public async drawEcology(refresh: boolean) {
        this.loadingGraph = true;
        const mapData = await insightStoreModule.getTeamsInsight(this.insight, this.dates, refresh)
        const svg = new SvgRenderInfoCell(this.$refs.svgPrimaryCanvas.canvas);
        svg.on('navigate', this.onNavigate);
        svg.setData(mapData.total);
        svg.render();
        this.primarySvg = svg;
        this.$refs.svgSecondaryCanvas.canvas.clear();
        this.loadingGraph = false;

        SvgAnimate.appear(svg.container);
    }

    public async drawSecondary() {
        if (this.secondarySvg.getId() === this.selectedId) {
            return;
        }

        const node = this.nodes.find((n: MapNode) => n.id === this.selectedId);
        if (node.data) {
            const svg = new SvgRenderInfoCell(this.$refs.svgSecondaryCanvas.canvas);
            svg.setData(node.data);
            svg.render();
            svg.fit();
            this.secondarySvg = svg;
        } else {
            this.secondarySvg = new SvgRenderInfoCell(this.$refs.svgSecondaryCanvas.canvas);
            this.$refs.svgSecondaryCanvas.canvas.clear();
        }
    }

    public onResize() {
        this.updateGraphSettings();
    }

    public async onSelectView(view: InsightView) {
        const query = { ...this.$route.query };
        query.insight = view.insightViewId.toString();
        await this.$router.replace({ query });
    }

    public async goUp() {
        const order = [MapView.Ecology, MapView.Team, MapView.Activity, MapView.Member];
        const level = order.findIndex((key => key === this.view));
        const query = { ...this.$route.query };
        const prop = order[level - 1];
        delete query[prop.toLowerCase()];
        await this.$router.push({ query });
    }

    public updateGraphSettings() {
        if (this.primarySvg.type === 'CellSpace') {
            const svg = this.primarySvg as SvgRenderCellSpace;
            svg.zoom(+this.zoom);
            svg.rotate(+this.rotate);
            svg.setMargin(+this.margin);
            svg.redraw();
            svg.fit();
        }

        if (this.secondarySvg) {
            const svg2 = this.secondarySvg as SvgRenderInfoCell;
            svg2.redraw();
            svg2.fit();
        }
    }

    public onZoom(zoom: number) {
        this.zoom = zoom;
        this.updateGraphSettings();
    }

    private getView(): MapView {
        if (this.activityId) {
            return MapView.Member;
        }

        if (this.teamId) {
            return MapView.Activity;
        }

        if (this.ecology) {
            return MapView.Team;
        }

        return MapView.Ecology;
    }

    private getInsight(): InsightView | null {
        if (this.$route.query && this.$route.query.insight) {
            return this.views.find((view) => view.insightViewId === +this.$route.query.insight) as InsightView;
        }
        return null;
    }

    private async onSelect(id: number | string) {
        if (id) {
            this.selectedId = id;
            await this.drawSecondary();
        }
    }

    private async onNavigate(id: number | string) {
        const query = { ...this.$route.query };
        const allowed = [MapView.Ecology, MapView.Team, MapView.Activity];

        if (this.primarySvg.type === 'InfoCell') {
            await SvgAnimate.disappear(this.primarySvg.container);
        }

        if (allowed.includes(this.view)) {
            query[this.view.toLowerCase()] = id.toString();
            this.loadingGraph = true;
            await this.$router.push({ query });
        }
    }
}
