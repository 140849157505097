import { Component } from 'vue-property-decorator';
import PageRender from '../../../models/PageRender';
import { groupHelper, teamService, teamHelper, personService, roleHelper, ecosystemHelper } from '@/main';
import moment from 'moment';
import Group from '@/models/Group';
import { VueEditor } from 'vue2-editor';
import to from 'await-to-js';
import { periodModule } from '@/store/modules/period';

@Component({
    components: {
        VueEditor,
    },
})
export default class TeamDirectoryAddMemberComponent extends PageRender {
    public stepIndex: number = 0;
    public energySpent: number = 0;
    public period: number = null;

    public isNotPresentInOtherTeam: boolean = false;

    public selectedGroupIds: any[] = [];
    public disableStuff: boolean = false;

    public memberToAdd: any = {
        lastName: '',
        insertion: '',
        firstName: '',
        phoneNumber: '',
        emailAddress: '',
        personId: 0,
        groupMemberships: [],
        accessUntil: null,
        createAccount: false,
    };

    public customControls: any = [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ];

    public validationMessages = [
        { step: 0, message: 'Fill in at least an email address or first and last name of the member you want to add.' },
        { step: 0, message: 'When access to team is set, e-mail address is required' },
        { step: 1, message: 'Select at least one group where the (new) team member will be member of' },
        { step: 2, message: 'The distributed energy needs to be an exact total of 100% and all groups <br/>' },
        { step: 2, message: 'All groups need at least a valid energy percentage' },
    ];

    public percentageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

    // tslint:disable-next-line: variable-name
    private _groups: Group[] = [];

    public async mounted() {
        if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
            await this.$router.push({ name: 'not-found' });
        }

        this.period = periodModule.selectedPeriodObject.periodId;
        this._groups = await groupHelper.getGroups();
    }

    get periods() {
        return periodModule.periods.map((period) => {
            return {
                text: moment(period.startDate).format(`MMMM 'YY`),
                value: period.periodId,
            };
        });
    }

    get groups() {
        let list = [];

        if (this._groups && this._groups.length > 0) {
            list = this._groups.map((group) => {
                return { text: group.name, value: group.groupId };
            });
        }

        return list;
    }

    get selectedGroups() {
        const list = [];
        const amount = this.selectedGroupIds.length === 1 ? 100 : 0;

        this._groups.forEach((group) => {
            if (this.selectedGroupIds.indexOf(group.groupId) > -1) {
                list.push({
                    groupId: group.groupId,
                    name: group.name,
                    groupKey: group.groupKey,
                    amount,
                });
            }
        });

        return list;
    }

    public updateEnergy(value?: number, groupId?: number) {
        this.energySpent = 0;

        if (value && groupId) {
            const fGroup = this.selectedGroups.find((x) => x.groupId === groupId);
            fGroup.amount = value * 1;
        }

        for (const group of this.selectedGroups) {
            this.energySpent += !isNaN(group.amount) ? parseInt(group.amount.toString()) : group.amount;
        }

        return this.energySpent;
    }

    public valid() {
        this.clearNotifications();

        if (this.stepIndex === 0) {
            return (
                (this.memberToAdd.firstName !== '' && this.memberToAdd.lastName !== '' && !this.memberToAdd.createAccoun) ||
                (this.memberToAdd.emailAddress !== '' && this.memberToAdd.createAccount && !this.isNotPresentInOtherTeam) ||
                (this.memberToAdd.emailAddress !== '' &&
                    this.memberToAdd.firstName !== '' &&
                    this.memberToAdd.lastName !== '' &&
                    this.isNotPresentInOtherTeam &&
                    this.memberToAdd.createAccoun)
            );
        } else if (this.stepIndex === 1) {
            return this.selectedGroupIds.length > 0;
        } else if (this.stepIndex === 2) {
            return (
                (this.memberToAdd.firstName !== '' && this.memberToAdd.lastName !== '' && !this.memberToAdd.createAccount) ||
                (this.memberToAdd.emailAddress !== '' && this.memberToAdd.createAccount && !this.isNotPresentInOtherTeam) ||
                (this.memberToAdd.emailAddress !== '' &&
                    this.memberToAdd.firstName !== '' &&
                    this.memberToAdd.lastName !== '' &&
                    this.isNotPresentInOtherTeam &&
                    this.memberToAdd.createAccount) ||
                (this.energySpent === 100 && this.selectedGroups.filter((x) => x.amount === 0).length === 0) ||
                this.selectedGroupIds.length > 0
            );
        }
    }

    public showValidationError() {
        return this.showValidationErrors(
            this.validationMessages
                .filter((x) => x.step === this.stepIndex)
                .map((x) => {
                    return x.message;
                }),
        );
    }

    public async searchEmailaddress() {
        this.showPending('CHECK_IF_MEMBER_EXISTS');
        const period = periodModule.periods.find((x) => x.periodId === this.period);

        const person = await personService.getPersonByEmailaddress(
            this.memberToAdd.emailAddress,
            moment(period.startDate, 'YYYY-MM-DD').format('YYYY-MM'),
            false,
        );

        if (person && person.personId) {
            this.memberToAdd.personId = person.personId;
            this.memberToAdd.firstName = person.firstName;
            this.memberToAdd.lastName = person.lastName;
            this.memberToAdd.createAccount = true;

            this.disableStuff = true;

            return this.clearAndShowSuccess('Person found and added to the add-member flow');
        }

        this.disableStuff = false;
        this.memberToAdd.personId = 0;

        this.clearNotifications();
    }

    public async next() {
        if (this.valid()) {
            this.stepIndex++;
        } else {
            this.showValidationError();
        }
    }

    public back() {
        this.stepIndex--;
    }

    public async finalize() {
        if (this.valid()) {
            await this.addMember();
        } else {
            this.showValidationError();
        }
    }

    public async addMember() {
        const period = moment(periodModule.periods.find((x) => x.periodId === this.period).startDate, 'YYYY-MM-DD').format('YYYY-MM');

        this.showPending('ADD_MEMBER_PENDING');

        this.memberToAdd.groupMemberships = this.selectedGroups.map((x) => {
            return { groupId: x.groupId, energyDedication: x.amount };
        });

        this.memberToAdd.accessFrom = this.period;
        const [inviteErr] = await to(teamService.addMember(this.memberToAdd, teamHelper.currentTeamId, period));
        if (inviteErr) {
            return this.clearAndShowError(`Failed to add ${this.memberToAdd.emailAddress} to the team`, inviteErr);
        }
        this.clearAndShowSuccess(`Member (${this.memberToAdd.emailAddress}) is added to the team.`);

        this.clearNotifications();
        await this.$router.push({
            name: 'team-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                tab: 'members',
                period: periodModule.selectedPeriod,
            },
        });
    }
}
