import RelationSettingsModal from '@/components/modals/relation-settings-modal';
import { financeService, relationService, roleHelper } from '@/main';
import CrossLegalTransaction from '@/models/Finance/CrossLegalTransaction';
import { IntellectualPropertyLimitation, RelationSettings } from '@/models/Finance/Relation';
import PageRender from '@/models/PageRender';
import { Component } from 'vue-property-decorator';
import moment from 'moment';
import { BModal } from 'bootstrap-vue';
import { Invoice } from '@/models/Invoice';
import Vue from 'vue';
import GenericSearch from '@/components/generic-search/generic-search';

@Component
export default class EcosystemSettingsTransactions extends PageRender {
    public isLoading: boolean = true;
    public refreshGrid: number = 0;
    public onlyWithoutSettings: boolean = true;

    public columns: any = [
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_MATCH_ID', field: 'matchId', width: 100 },
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_AMOUNT', field: 'amount', width: 100 },
        // { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_INVOICE', field: 'invoice.invoiceId', width: 100 },
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_FROM', field: 'originTeam.name', cell: this.renderFrom },
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_TO', field: 'originTeam.name', cell: this.renderTo },
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_DATE_HANDLED', field: 'dateHandled', cell: this.renderInvoiceDate },
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_DATE_CREATED', field: 'dateCreated', cell: this.renderDateCreated },
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_STATUS', field: 'status', width: 100 },
        { title: 'CROSS_LEGAL_ENITIY_TRANSACTION_SETTINGS', field: 'relationSettings', width: 150, cell: this.viewSettings },
        { title: 'INVOICE', field: 'relationSettings', width: 150, cell: this.viewInvoice },
    ];
    public transactions: CrossLegalTransaction[] = [];
    public allTransactions: CrossLegalTransaction[] = [];
    public invoice: Invoice = new Invoice();
    public intellectualPropertyLimitations: IntellectualPropertyLimitation[] = [];

    public $refs!: {
        relationModal: RelationSettingsModal;
        invoiceInfoModal: BModal;
        searchBox: GenericSearch;
    };

    public async created() {
        this.allTransactions = await financeService.getCrossLegalTransactions();
        this.filterRelations();
        this.intellectualPropertyLimitations = (await relationService.getRelationProperties()).data.intellectualPropertyLimitations;

        this.isLoading = false;
    }

    public filterRelations() {
        if (this.onlyWithoutSettings) {
            this.transactions = this.allTransactions.filter((x) => !x.relationSettings);
        } else {
            this.transactions = this.allTransactions;
        }

        this.refreshGrid++;
    }

    public async updateGridData(dataItems) {
        if (!dataItems || !dataItems.length) {
            // do search
            this.transactions = await financeService.getCrossLegalTransactions(this.$refs.searchBox.searchTerm);

            if (this.transactions.length) {
                this.showSuccess(`Found  ${this.transactions.length} matching transaction(s) in other periods`);
            }
            this.refreshGrid++;
            return;
        }

        this.transactions = dataItems;

        this.refreshGrid++;
    }

    public openSettingsModal(settings: RelationSettings) {
        if (settings) {
            this.$refs.relationModal.setData(null, settings);
            this.$refs.relationModal.show();
        }
    }

    public openInvoiceModal(invoice: Invoice) {
        if (invoice) {
            this.invoice = invoice;
            this.$refs.invoiceInfoModal.show();
        }
    }

    public renderFrom(h, _, row) {
        let from = '';

        if (row.dataItem.originLegalEntityReference) {
            from += row.dataItem.originLegalEntityReference;
        }

        if (row.dataItem.originTeam) {
            from += ` - ${row.dataItem.originTeam.name}`;
        }

        if (row.dataItem.originGroup) {
            from += ` - ${row.dataItem.originGroup.name}`;
        }

        return h('td', [from]);
    }

    public renderTo(h, _, row) {
        let from = '';

        if (row.dataItem.destinationLegalEntityReference) {
            from += row.dataItem.destinationLegalEntityReference;
        }

        if (row.dataItem.destinationTeam) {
            from += ` - ${row.dataItem.destinationTeam.name}`;
        }

        if (row.dataItem.destinationGroup) {
            from += ` - ${row.dataItem.destinationGroup.name}`;
        }

        return h('td', [from]);
    }

    public renderDateCreated(h, _, row) {
        return h('td', [moment(row.dataItem.dateCreated, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')]);
    }

    public renderInvoiceDate(h, _, row) {
        return h('td', [moment(row.dataItem.dateHandled, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')]);
    }

    public viewInvoice(h, _, props) {
        return h(
            'td',
            {
                class: 'btn-link cursor-pointer',
                on: {
                    click: () => {
                        this.openInvoiceModal(props.dataItem.invoice);
                    },
                },
            },
            [props.dataItem.invoice.invoiceId],
        );
    }

    public viewSettings(h, _, props) {
        return h(
            'td',
            {
                class: 'btn-link cursor-pointer',
                on: {
                    click: () => {
                        this.openSettingsModal(props.dataItem.relationSettings);
                    },
                },
            },
            [props.dataItem.relationSettings ? 'Set (view)' : 'Not set'],
        );
    }

    public getContent(): string {
        let content = `
        <div class="p-2">
            <h6 class="mb-0">Client</h6>
            <p class="text-muted mb-2">${this.invoice.name}</p>

            <h6 class="mb-0">Description</h6>
            <p class="text-muted mb-2">${this.invoice.description}</p>
        `;

        if (this.invoice.reference) {
            content += `
                <h6 class="mb-0">Reference</h6>
                <p class="text-muted mb-2">${this.invoice.reference}</p>
            `;
        }

        content += `
            <h6 class="mb-0">Date</h6>
            <p class="text-muted mb-2">${this.getDate()}</p>
        `;

        if (this.invoice.originalAmount && this.invoice.parent) {
            content += `<h6 class="mb-0">Original amount</h6>` + `<p class="text-muted mb-2">${Vue.filter('number-format')(this.invoice.originalAmount)}</p>`;
        }

        if (this.invoice.invoiceAmortization && this.invoice.invoiceAmortization.account) {
            content +=
                `<h6 class="mb-0">Amortization setting</h6>` +
                `<p class="text-muted mb-2">Distributes automatically ${this.getAmortizationAmountFormat()} per period to ${
                    this.invoice.invoiceAmortization.account.name
                }</p>`;
        }

        if (roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) {
            content +=
                `<h6 class="mb-0">Legal entity reference</h6>` +
                `<p class="text-muted mb-2">${!this.invoice.legalEntityReferenceOrigin ? '-' : this.invoice.legalEntityReferenceOrigin}</p>`;
        }

        content += `<h6 class="mb-0">Invoice Id</h6>` + `<p class="text-muted mb-2">${this.invoice.invoiceId}</p>` + '</div>';

        return content;
    }

    public getDate(): string {
        return moment(this.invoice.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }

    public getAmortizationAmountFormat() {
        if (this.invoice.invoiceAmortization.amortizationAmount) {
            return Vue.filter('number-format')(this.invoice.invoiceAmortization.amortizationAmount);
        }

        return '0';
    }
}
