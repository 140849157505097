import Component from 'vue-class-component';
import Attribution from '../../../../models/Attribution';
import {
    attributionService,
    teamHelper,
    groupService,
    teamService,
    financeService,
    ecosystemHelper,
    templateService,
    groupHelper,
    financeGroupService,
} from '@/main';
import { AttributionType } from '../../../../models/AttributionType';
import BaseGroupPage from './BaseGroupPage';
import to from 'await-to-js';
import Team from '@/models/Team';
import GroupAlias from '@/models/GroupAlias';
import { AxiosResponse } from 'axios';
import FinancialGroupActivity from '@/models/FinancialGroupActivity';
import TeamSettings from '@/models/TeamSettings';
import { PrivacyLevel } from '@/models/PrivacyLevel';
import { periodModule } from '@/store/modules/period';

@Component
export default class GroupDetailSettings extends BaseGroupPage {
    public teams: Team[] = [];

    public groupAttributionsLoading: boolean = true;
    public resourceAttributionsLoading: boolean = true;

    public groupAttributions: Attribution[] = [];
    public resourceAttributions: Attribution[] = [];

    public teamFinanceSettings: TeamSettings = null;

    public importServiceAttributionGroupId?: number = null;
    public copingLoading: boolean = false;
    public aliases: any[] = [];

    public groupType: any = 'Experts';
    public templates: any[] = [];
    public selectedTemplate: any = null;
    public templateEdited: boolean = false;

    public financialGroupActivity: FinancialGroupActivity = new FinancialGroupActivity();

    public groupFinanceSettings: any = {
        applyMissingAttributionsOnTransfer: false,
        autoDistributeIncomeToSavings: false,
        autoDistributeExpensesToSavings: false,
        autoCompensateGroupMembers: false,
        privacyLevel: PrivacyLevel.Unknown,
    };

    public roleOptions: any[] = [
        { text: 'Site Admin', value: 'SiteAdmin' },
        { text: 'Finance Employee', value: 'FinanceEmployee' },
        { text: 'Group Lead', value: 'GroupLead' },
        { text: 'Member', value: 'Member' },
    ];

    public get derivedFromTemplate() {
        return this.group.baseTemplate && this.group.baseTemplate > 0;
    }

    public async created() {
        const self = this;
        this.section = this.$route.meta.section;

        await this.initSettingsGroup();

        if (this.section === 'directory') {
            if (this.group.isConnectorMember(this.connectors.find((x) => x.connectorId === 1))) {
                this.financialGroupActivity = await this.checkValidDelete();
            }
        }

        self.teams = await teamService.getTeams();

        const groupAttributionResponse = await attributionService.getAttributions(
            AttributionType.GroupRemittance,
            teamHelper.currentTeam.id,
            periodModule.selectedPeriod,
            this.group.groupId,
        );
        self.groupAttributions = groupAttributionResponse.data;
        self.groupAttributionsLoading = false;

        const resourceAttributionsResponse = await attributionService.getAttributions(
            AttributionType.GroupAttribution,
            teamHelper.currentTeam.id,
            periodModule.selectedPeriod,
            this.group.groupId,
        );

        const attrs = resourceAttributionsResponse.data.map((item) => {
            const attr = new Attribution(0);
            Object.assign(attr, item);

            attr.targetTeamModel = self.teams.find((team) => {
                if (item.targetTeam) {
                    return team.teamId === item.targetTeam;
                }

                return team.teamId === teamHelper.getTeamId();
            });

            return attr;
        });

        attrs.forEach(async (attribution) => {
            attribution.groups = await financeGroupService.getGroups(attribution.targetTeamModel.teamId);

            attribution.targetGroupModel = attribution.groups.find((group) => {
                return group.groupId === attribution.targetGroup;
            });
        });

        self.resourceAttributions = attrs;
        self.resourceAttributionsLoading = false;

        await this.loadGroupFinanceSettings();
        await this.loadTeamFinanceSettings();
        await this.loadTemplates();

        if (this.group.baseTemplate) {
            this.selectedTemplate = this.templates.find((x) => x.financeGroupTemplateId === this.group.baseTemplate);
        }

        this.group.aliases = await this.getGroupAliases(this.group.groupId);
        if (!this.group.aliases) {
            this.group.aliases = new GroupAlias({ teamKey: teamHelper.currentTeam.key, groupKey: this.group.groupKey });
        }
        this.aliases = this.getGroupAliasesFromGroup();

        this.groups = this.teams.find((x) => x.teamId === teamHelper.getTeamId()).groups;

        this.isLoading = false;
    }

    public async addGroupAttribution() {
        this.groupAttributions.push(new Attribution(0));
    }

    public async loadTemplates() {
        this.templates = await templateService.getTemplates(teamHelper.currentTeam.id);
    }

    public getTemplateName() {
        const template = this.templates.find((x) => x.financeGroupTemplateId === this.group.baseTemplate);
        return template ? template.name : 'n/a';
    }

    public getGroupAliasesFromGroup() {
        if (!this.group.aliases) {
            return [];
        }

        return this.group.aliases.aliases.map((alias) => {
            return { text: alias, teamKey: this.group.aliases.teamKey, groupKey: this.group.aliases.groupKey };
        });
    }

    public async getGroupAliases(groupId: number) {
        const [err, response] = await to<AxiosResponse<GroupAlias>>(financeService.getGroupAliases(groupId));
        if (err) {
            this.clearAndShowError('Failed to update group', null);
            return new GroupAlias();
        }

        return response.data;
    }

    public deleteGroupAlias(alias) {
        this.aliases.splice(this.aliases.indexOf(alias), 1);
    }

    public addGroupAlias() {
        this.aliases.push({ text: '', teamKey: this.group.aliases.teamKey, groupKey: this.group.aliases.groupKey });
    }

    public async saveAliases() {
        this.showPending('SAVE_ALIASES_PENDING');
        const aliases = this.group.aliases;
        aliases.aliases = this.aliases.map((x) => x.text);
        const [errAlias] = await to(financeService.upsertGroupAliases(this.group.groupId, aliases));
        if (errAlias) {
            return this.clearAndShowError('SAVE_ALIASES_FAILED', null);
        }

        this.clearAndShowSuccess('SAVE_ALIASES_SUCCESS');
    }

    public async addServiceAttribution() {
        const attribution = new Attribution(0);
        attribution.targetTeamModel = this.teams.find((team) => {
            return team.teamId === teamHelper.getTeamId();
        });
        attribution.groups = await financeGroupService.getGroups(attribution.targetTeamModel.teamId);

        this.resourceAttributions.push(attribution);
    }

    public deleteGroupAttribution(item: Attribution) {
        this.groupAttributions.splice(this.groupAttributions.indexOf(item), 1);
    }

    public deleteServiceAttribution(item: Attribution) {
        this.resourceAttributions.splice(this.resourceAttributions.indexOf(item), 1);
    }

    public async teamChange(attribution: Attribution) {
        attribution.targetGroup = null;
        attribution.groups = await financeGroupService.getGroups(attribution.targetTeamModel.teamId);
    }

    public async getTeamGroups(team: Team) {
        const [err, response] = await to(groupService.getGroups(team.teamId, periodModule.selectedPeriod));
        if (err) {
            this.showFailedResponse(`Failed to load groups for team ${team.teamKey}`, null);
        }

        return response.data;
    }

    public async importServiceAttribution() {
        const self = this;

        this.copingLoading = true;
        const currentTeamId = teamHelper.getTeamId(teamHelper.currentTeamId);

        await attributionService
            .getAttributions(AttributionType.GroupAttribution, currentTeamId, periodModule.selectedPeriod, this.importServiceAttributionGroupId)
            .then((result) => {
                result.data.forEach(async (attribution: Attribution) => {
                    attribution.attributionId = undefined;
                    attribution.group = self.group.groupId;
                    const foundTeam = self.teams.find((team) => team.teamId === attribution.targetTeam);
                    attribution.targetTeamModel = foundTeam;
                    const groups = (await teamService.getGroups(foundTeam.teamId, periodModule.selectedPeriod)).data;
                    attribution.targetGroupModel = groups.find((group) => group.groupId === attribution.targetGroup);
                    attribution.groups = groups;

                    self.resourceAttributions.push(attribution);
                });
            });

        this.copingLoading = false;
    }

    public async saveCollaborationMode() {
        this.showPending('SAVE_COLLABORATION_PENDING');
        const [errAlias] = await to(financeService.saveCollaborationMode(this.group.groupId, this.group.collaborationMode));
        if (errAlias) {
            return this.clearAndShowError('SAVE_COLLABORATION_FAILED', null);
        }

        this.clearAndShowSuccess('SAVE_COLLABORATION_SUCCESS');
    }

    public async openSaveAttributionsModal() {
        let invalid = false;
        this.resourceAttributions.forEach((attr) => {
            if (
                typeof attr.targetGroupModel === 'undefined' ||
                attr.targetGroupModel === null ||
                !attr.targetGroupModel ||
                typeof attr.targetTeamModel === 'undefined' ||
                !attr.targetTeamModel ||
                attr.targetTeamModel === null ||
                !attr.percentage ||
                attr.percentage < 0 ||
                attr.percentage > 100
            ) {
                invalid = true;
            }
        });

        this.groupAttributions.forEach((attr) => {
            if (!attr.name || !attr.percentage || attr.percentage < 0 || attr.percentage > 100) {
                invalid = true;
            }
        });

        if (invalid) {
            return this.clearAndShowWarning('Current state of attributions not valid, please fill in all fields');
        }

        if (this.group.baseTemplate) {
            this.$refs.templateEditWarning.show();
        } else {
            this.$refs.saveAttributionsModal.show();
        }
    }

    public async checkValidDelete() {
        const [err, response] = await to<AxiosResponse<FinancialGroupActivity>>(
            financeService.getGroupFinancialActiviy(teamHelper.getTeamId(), this.group.groupId, periodModule.selectedPeriod),
        );
        if (err) {
            this.clearAndShowError('Failed to check financial activity for this group.', err);
        }

        return response.data;
    }

    public async saveFinanceSettings() {
        this.showPending('SAVE_FINANCE_SETTINGS_PENDING');
        const [err] = await to(
            attributionService.saveAttributionSetting(
                this.group.groupId,
                this.groupFinanceSettings.applyMissingAttributionsOnTransfer,
                this.groupFinanceSettings.autoDistributeIncomeToSavings,
                this.groupFinanceSettings.autoDistributeExpensesToSavings,
                this.groupFinanceSettings.autoCompensateGroupMembers,
                this.groupFinanceSettings.privacyLevel,
            ),
        );

        if (err) {
            return this.clearAndShowError('SAVE_FINANCE_SETTINGS_FAILED', err);
        }

        this.clearAndShowSuccess('SAVE_FINANCE_SETTINGS_SUCCESS');
    }

    public async deleteGroup() {
        const result = await this.checkValidDelete();
        if (!result.hasActivity) {
            this.$refs.deleteConfirm.show();
        } else {
            let errorMessage = `Conditions to delete this group aren't met. <br/> <br/> Failed on:`;

            if (result.isAttributionGroup) {
                errorMessage += `<br/> - Group is used as an attribution group`;
            }
            if (result.expensesInvoiceCount) {
                errorMessage += `<br/> - Group has open expenses`;
            }
            if (result.incomeInvoicesCount) {
                errorMessage += `<br/> - Group has open invoices`;
            }
            if (result.savingBalance) {
                errorMessage += `<br/> - Group balance isn't zero`;
            }

            this.clearAndShowError(errorMessage, null);
        }
    }

    public async updateTemplate(showMessages: boolean = true) {
        if (showMessages) {
            this.showPending('SAVE_GROUP_PENDING');
        }
        this.group.baseTemplate = this.selectedTemplate.financeGroupTemplateId;

        const group = await groupHelper.createOrUpdateGroup(this.group);

        if ((!group || group.groupId === 0) && showMessages) {
            return this.clearAndShowError('SAVE_GROUP_FAILED', null);
        }

        this.templateEdited = false;
        if (showMessages) {
            this.clearAndShowSuccess('SAVE_GROUP_SUCCESS');
        }
    }

    public async updateTemplateOfGroup() {
        if (this.group.baseTemplate) {
            await this.updateTemplate(false);
        }
    }

    public async confirmDelete() {
        const self = this;
        this.showPending('DELETE_GROUP_PENDING');

        const [err] = await to(groupService.deleteGroup(this.group.groupId, teamHelper.currentTeamId, periodModule.selectedPeriod));
        if (err) {
            return this.clearAndShowError('DELETE_GROUP_FAILED', err);
        }

        self.$refs.deleteConfirm.hide();

        this.clearAndShowSuccess('DELETE_GROUP_SUCCESS');
        return this.$router.push({
            name: 'team-directory',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
                tab: 'groups',
                period: periodModule.selectedPeriod,
            },
        });
    }

    public showRemoveAllAttributionsPointedToMeModal() {
        this.$refs.confirmRemoveAttributions.show();
    }

    public cancelAttributionsDelete() {
        this.$refs.confirmRemoveAttributions.hide();
    }

    public async confirmDeleteAttributions() {
        const success = await attributionService.removeAttributionSources(teamHelper.currentTeamId, this.group.groupId);
        if (success) {
            this.financialGroupActivity = await this.checkValidDelete();
            this.$refs.confirmRemoveAttributions.hide();
        }
    }

    public cancelDelete() {
        this.$refs.deleteConfirm.hide();
    }
}
