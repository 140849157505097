import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { teamHelper, ecosystemHelper, roleHelper } from '@/main';
import { Membership } from '@/models/Interfaces';
import { Route } from 'vue-router';
import { profileModule } from '@/store/modules/profile';
import { periodModule } from '@/store/modules/period';

@Component
export default class NavigationTeam extends Vue {
    public isEcoScope: boolean = false;
    public teamChanged: number = 1;

    public created() {
        this.isEcoScope = this.$route.meta.scope === 'ecosystem';
    }

    public updated() {
        this.isEcoScope = this.$route.meta.scope === 'ecosystem';
    }

    public currentTeam() {
        if (profileModule.brightProfile && profileModule.brightProfile.teamMemberships) {
            return profileModule.brightProfile.teamMemberships.find((membership: Membership) => {
                return membership.key === teamHelper.currentTeam.key;
            });
        }

        return {} as Membership;
    }

    public get otherTeams(): any[] {
        if (profileModule.brightProfile && roleHelper.isAccountant()) {
            return profileModule.brightProfile.teamMemberships;
        }

        if (
            !profileModule.brightProfile ||
            !profileModule.brightProfile.teamMemberships.filter(
                (x) =>
                    x.ecosystem === ecosystemHelper.currentEcosystem.id &&
                    x.accessFrom >= periodModule.selectedPeriodObject.periodId &&
                    (!x.accessUntil || x.accessFrom <= periodModule.selectedPeriodObject.periodId),
            )
        ) {
            return [];
        }

        return profileModule.brightProfile.teamMemberships.filter(
            (x) =>
                x.ecosystem === ecosystemHelper.currentEcosystem.id &&
                x.accessFrom <= periodModule.selectedPeriodObject.periodId &&
                (!x.accessUntil || x.accessFrom <= periodModule.selectedPeriodObject.periodId),
        );
    }

    public async switchTo(team: Membership): Promise<Route> {
        ecosystemHelper.setEcosystemByEcosystemId(team.ecosystem);
        teamHelper.setTeam(team);

        await periodModule.init();

        const route = this.$route;
        const params = Object.assign({}, route.params);
        params.teamKey = team.key;
        params.teamId = team.id.toString();

        const ecosystem = profileModule.brightProfile.ecosystemMemberships.find((x) => x.id === team.ecosystem);
        params.ecosystemKey = ecosystem.key;
        params.ecosystemId = ecosystem.id.toString();

        if (typeof route.params.groupKey !== 'undefined') {
            const sub =
                this.$route.fullPath.indexOf('finance') > -1 ? 'finances' : this.$route.fullPath.indexOf('insights') > -1 ? 'insights' : 'directory';
            const tab =
                this.$route.fullPath.indexOf('finance') > -1
                    ? 'group-overview'
                    : this.$route.fullPath.indexOf('insights') > -1
                    ? 'group-results'
                    : 'groups';

            params.tab = tab;
            return this.$router.push({
                name: `team-${sub}`,
                params,
            });
        }

        this.teamChanged++;

        return this.$router.push({
            name: route.name,
            params,
        });
    }
}
