import FinanceBase from './FinanceBase';
import DistributedInvoice from './DistributedInvoice';
import { Distribution } from './Interfaces';
import Group from './Group';
import { transferService } from '@/main';
import Transfer from './Transfer';
import { InvoiceType } from './InvoiceType';
import Vue from 'vue';
import AccountFunds from './Finance/Api/AccountFunds';
import Range from './Finance/Api/Range';
import { AccountType } from './AccountType';

export default class FinanceGroup extends FinanceBase {
    public compensations: any[];
    public attributions: any[];
    public transfers: Transfer[];
    public account: any;
    public group: Group;
    public teamId: number;
    public totalCosts: any; // Cost are internal expenses
    public totalCompensations: any;
    public avarageEnergyDedication: any = 'N/A';

    constructor(
        teamId = 0,
        group = {} as Group,
        invoices = [],
        expenses = [],
        transfers = [],
        compensations = [],
        attributions = [],
        currentBalance: Range<AccountFunds> = new Range<AccountFunds>(),
    ) {
        super(invoices, expenses);

        this.group = group;
        this.teamId = teamId;
        this.expenses = this.filterEmptyInvoices(expenses);
        this.transfers = transfers;
        this.compensations = compensations;
        this.attributions = attributions;
        this.currentBalanceAmount =
            currentBalance && currentBalance.items.length > 0 ? currentBalance.items[currentBalance.items.length - 1].item.balance : 0;
        const previousBalance =
            currentBalance && currentBalance.items.length > 1 ? currentBalance.items[currentBalance.items.length - 2].item.balance : 0;

        this.totalIncomesAmount = this.calculateGroupIncome();
        this.totalExpensesAmount = this.calculateTotalDistributions(this.expenses, AccountType.GroupSavings);
        this.totalExternalTransferAmount = this.calculateTotalTranfers(
            this.transfers.filter((transfer: Transfer) => {
                return transfer.transferType.toString() === 'GroupToGroup';
            }),
        );
        this.calculateCosts();
        this.calculateResults();
        this.calculateCompensations();

        this.setTotals(
            this.totalIncomesAmount,
            this.totalExpensesAmount,
            this.totalCostAmount,
            this.totalResultAmount,
            previousBalance,
            this.currentBalanceAmount,
            this.totalExternalTransferAmount,
        );
    }

    public calculateCosts() {
        this.totalCostAmount = this.calculateGroupCosts();
        this.totalCosts = Vue.filter('number-format')(this.totalCostAmount);
    }

    public calculateResults() {
        this.totalResultAmount = this.totalIncomesAmount - this.totalCostAmount;
        this.totalResult = Vue.filter('number-format')(this.totalResultAmount);
    }

    public async recalculateTotals() {
        this.calculateCompensations();
        this.calculateCosts();
        this.calculateResults();
    }

    public setCompensations(compensations) {
        this.compensations = compensations;
    }

    public calculateGroupSavings() {
        let total = 0;
        this.invoices.forEach((invoice: DistributedInvoice) => {
            invoice.getGroupSavings(this.group.groupId).forEach((distribution: Distribution) => {
                total += distribution.amount;
            });
        });
        return total;
    }

    public calculateCompensations() {
        let compensations = 0;
        this.compensations.forEach((compensation: any) => {
            compensations += compensation.compensation;
            compensations += compensation.invoiceIncome;
        });

        this.totalCompensations = Vue.filter('number-format')(compensations);

        return compensations;
    }

    public calculateGroupIncome() {
        let attributions = 0;
        let totalAttributedDistributions = 0;
        let totalNonAttributedDistributions = 0;
        let totalAttributions = 0;

        this.attributions.forEach((attribution: any) => {
            attributions += attribution.percentage;
        });

        totalAttributedDistributions = this.calculateTotalDistributions(
            this.invoices.filter((distributedInvoice: DistributedInvoice) => {
                return distributedInvoice.invoice.invoiceType !== InvoiceType.InternalIncome;
            }),
        );

        totalNonAttributedDistributions = this.calculateTotalDistributions(
            this.invoices.filter((distributedInvoice: DistributedInvoice) => {
                return distributedInvoice.invoice.invoiceType === InvoiceType.InternalIncome;
            }),
        );

        totalAttributions = (totalAttributedDistributions * attributions) / 100;

        // client income (-group attributions) + group savings
        return totalAttributedDistributions + totalNonAttributedDistributions - totalAttributions;
    }

    public calculateGroupCosts() {
        // compensations - expenses
        return (
            this.calculateCompensations() +
            this.calculateTotalDistributions(this.expenses, AccountType.GroupSavings) +
            this.totalExternalTransferAmount
        );
    }

    public calculateTotalTranfers(transfers: Transfer[]) {
        let transferTotal = 0;

        transfers.forEach((transfer: any) => {
            transferTotal += transfer.amount;
        });
        return transferTotal;
    }

    public async reloadTransfers(team, groupKey, period) {
        return await transferService.getTransfers(team, groupKey, null, period);
    }
}
