import Component from 'vue-class-component';
import Vue from 'vue';
import BaseFinancePage from './BaseFinancePage';
import DistributedInvoice from '@/models/DistributedInvoice';
import { Invoice } from '@/models/Invoice';
import { BModal, BFormFile } from 'bootstrap-vue';
import { functions } from '@/helpers/functions';
import { financeService, teamHelper, financeAccountService } from '@/main';
import to from 'await-to-js';
import { enMessages } from '@/language/en';
import { Distribution, FinanceAccount } from '@/models/Interfaces';
import AmortizeModalComponent from '@/components/modals/amortize-modal';
import { InvoiceType } from '@/models/InvoiceType';
import { AccountType } from '@/models/AccountType';
import moment from 'moment';
import Team from '@/models/Team';
import { periodModule } from '@/store/modules/period';
import ChangeLegalEntityReferenceModal from '@/components/modals/change-legal-entity-reference-modal';

@Component
export default class BaseFinanceTeamInvoicePage extends BaseFinancePage {
    public invoices: DistributedInvoice[] = [];
    public allInvoices: DistributedInvoice[] = [];
    public reloadInvoicesGrid: number = 0;

    public importedInvoices: Invoice[] = [];
    public isUploadingCsv: boolean = false;

    public isReloadingGrid: boolean = false;
    public disableGrid: boolean = false;

    public skip: number = 0;
    public take: number = 50;
    public columnWidth: number = 175;

    public isLoaded: boolean = false;

    public textSearch: string = '';
    public onlyNotYetDistributed: boolean = false;
    public onlyRecurring: boolean = false;

    public groups: FinanceAccount[] = [];
    public members: FinanceAccount[] = [];

    public selectedField: string = 'selected';
    public receivingAccount: number = null;
    public editID: number = null;

    public team: Team = null;

    public $refs!: {
        importInvoice: BModal;
        fileInput: BFormFile;
        deleteConfirm: BModal;
        multiIncomeDistributionModal: BModal;
        amortizeModal: AmortizeModalComponent;
        legalEntityRef: ChangeLegalEntityReferenceModal;
    };

    public importedInvoiceColumns: any[] = [
        { field: 'CUSTOMER', title: 'Customer' },
        { field: 'REFERENCE', title: 'Reference' },
        { field: 'DESCRIPTION', title: 'Description' },
        { field: 'AMOUNT', title: 'Amount', cell: this.formatImportedInvoiceAmountValue },
        { field: 'INVOICE_DATE', title: 'Date', cell: this.renderInvoiceDate },
    ];

    public invoiceColumns: any[] = [
        { field: 'selected', width: 60, locked: true, filterable: false, className: 'bg-white' },
        {
            cell: this.renderInvoicePopoverDistributionGrid,
            title: 'INVOICE',
            locked: true,
            width: 200,
            editable: false,
            field: 'invoice.name',
            filterable: false,
            headerCell: this.translate,
        },
        {
            cell: this.formatInvoiceAmountValue,
            title: 'AMOUNT',
            locked: true,
            width: 200,
            editable: false,
            filterable: false,
            field: 'invoice.amount',
            filter: 'text',
            headerCell: this.translate,
        },
        {
            cell: this.renderAmountLeft,
            title: 'AMOUNT_LEFT',
            locked: true,
            width: 200,
            editable: false,
            filterable: false,
            field: 'amountLeftFilter',
            headerCell: this.translate,
        },
    ];

    public async init(invoiceType: InvoiceType) {
        this.groups = (await financeAccountService.getAccounts(AccountType.GroupIncome, teamHelper.currentTeam.id, periodModule.selectedPeriod)).data;

        for (let i = 0; i < this.groups.length; i++) {
            const groupId = this.groups[i].relatedEntityId;

            this.invoiceColumns.push({
                width: this.columnWidth + 50,
                field: `group_${groupId}`,
                title: this.groups[i].name,
                locked: false,
                cell: this.renderDistribution,
                filterable: false,
            });
        }

        if (invoiceType === InvoiceType.Expenses) {
            const personAccounts = (await financeAccountService.getAccounts(
                AccountType.Person,
                teamHelper.currentTeam.id,
                periodModule.selectedPeriod,
            )).data;

            for (let i = 0; i < personAccounts.length; i++) {
                const account = personAccounts[i];
                const memberId = account.relatedEntityId;

                if (account) {
                    this.invoiceColumns.push({
                        width: this.columnWidth + 50,
                        field: `member_${memberId}`,
                        title: account.name,
                        locked: false,
                        cell: this.renderDistribution,
                        filterable: false,
                    });

                    this.members.push(account);
                }
            }
        }

        await this.loadInvoices(invoiceType);

        if (this.$route.query.invoiceId || this.$route.query.invoice) {
            this.textSearch = this.$route.query.invoice ? this.$route.query.invoice.toString() : this.$route.query.invoiceId.toString();
            this.filterInvoices();
        }
    }

    public async reloadGrid(invoiceType: InvoiceType) {
        this.showPending('RELOAD_INVOICES_PENDING');
        this.isReloadingGrid = true;
        await this.loadInvoices(invoiceType);
        this.isReloadingGrid = false;
        this.reloadInvoicesGrid++;
        this.clearNotifications();
    }

    public async loadInvoices(invoiceType: InvoiceType) {
        const [err, response] = await to(financeService.getInvoices(teamHelper.getTeamId(), null, invoiceType, null, periodModule.selectedPeriod));
        if (err) {
            this.showError('LOAD_EXPENSES_FAILED');
        }

        const invoices = response.filter((invoice) => {
            return invoice.invoice.source !== 'InternalTransfer';
        });

        invoices.forEach((item: DistributedInvoice) => {
            item.invoice.date = moment(item.invoice.invoiceDate, 'YYYY-MM-DD').toDate();
            for (let i = 0; i < this.groups.length; i++) {
                const distributions = item.distributions.filter((d) => {
                    if (d.account && this.groups[i]) {
                        return d.account.accountId === this.groups[i].accountId;
                    }
                });

                if (distributions && distributions.length === 0) {
                    item.distributions.push({ amount: 0, account: this.groups[i] });
                }

                if (distributions.length > 1) {
                    const amount = distributions.reduce((total, distribution) => total + distribution.amount, 0);
                    const filteredDistributions = item.distributions.filter((distribution) => {
                        if (distribution.account) {
                            return distribution.account.accountId !== this.groups[i].accountId;
                        }
                    });

                    item.distributions = filteredDistributions;
                    item.distributions.push({ amount, account: distributions[0].account } as Distribution);
                }

                item.selected = false;
                item.amountLeft = functions.amountLeft(item);
                item.amountLeftFilter = item.amountLeft;
            }

            if (invoiceType === InvoiceType.Expenses) {
                for (let i = 0; i < this.members.length; i++) {
                    const distributions = item.distributions.filter((d) => {
                        if (d.account && this.members[i]) {
                            return d.account.accountId === this.members[i].accountId;
                        }
                    });

                    if (distributions && distributions.length === 0) {
                        item.distributions.push({ amount: 0, account: this.members[i] });
                    }

                    item.selected = false;
                }
            }
        });

        this.allInvoices = this.invoices = invoices;
    }

    public async updateDistribution(a) {
        const distributedInvoice = a.dataItem;
        const split = a.prop.split('_');
        let accountType = AccountType.Person;
        if (split[0].indexOf('group') > -1) {
            accountType = AccountType.GroupIncome;
        }

        const distribution = distributedInvoice.distributions.find(
            (d) =>
                d.account && d.account.relatedEntityId && d.account.relatedEntityId === parseInt(split[1]) && d.account.accountType === accountType,
        );
        distribution.amount = a.value;
    }

    public async removeAndSaveCurrentDistributions(invoice: DistributedInvoice) {
        invoice.distributions.forEach((x) => (x.amount = 0));
        await this.saveRow({ dataItem: invoice, value: 0, prop: '_' });
        this.reloadInvoicesGrid++;
    }

    public async saveRow(a) {
        if (this.disableGrid) {
            return;
        }
        const self = this;

        const distributedInvoice = a.dataItem;
        const split = a.prop.split('_');
        let accountType = AccountType.Person;
        if (split[0].indexOf('group') > -1) {
            accountType = AccountType.GroupIncome;
        }

        const distribution = distributedInvoice.distributions.find(
            (d) =>
                d.account && d.account.relatedEntityId && d.account.relatedEntityId === parseInt(split[1]) && d.account.accountType === accountType,
        );
        if (distribution) {
            distribution.amount = a.value;
        }

        const amount = functions.amountLeft(distributedInvoice, distributedInvoice.invoice.amount < 0);
        if ((amount >= 0 && distributedInvoice.invoice.amount > 0) || (distributedInvoice.invoice.amount < 0 && amount <= 0)) {
            self.showPending('DISTRIBUTING_INVOICES_PENDING');
            this.disableGrid = true;
            const [err] = await to(financeService.saveDistribution(distributedInvoice, teamHelper.getTeamId()));
            if (err) {
                self.clearAndShowError('DISTRIBUTING_INVOICES_FAILED', err);
            } else {
                self.clearAndShowSuccess('DISTRIBUTING_INVOICES_SUCCESS');

                Vue.set(distributedInvoice, 'inEdit', false);
                Vue.set(distributedInvoice, 'amountLeftFilter', distributedInvoice.amountLeft);

                this.editID = null;
            }

            this.disableGrid = false;
        } else {
            return self.showError(enMessages.notAllowedToDistribute);
        }
    }

    public async openModalMultiDistribution() {
        const modal = this.$refs.multiIncomeDistributionModal;
        modal.show();
    }

    public optionListReceivers(): any[] {
        const list = this.groups.map((sender) => {
            if (sender) {
                return { value: sender.accountId, text: sender.name };
            }
        });
        return list;
    }

    public openModalImportInvoices(): void {
        const self = this;
        const modal = self.$refs.importInvoice;
        modal.show();
    }

    public deleteRow() {
        this.$refs.deleteConfirm.show();
    }

    public cancelDelete() {
        this.$refs.deleteConfirm.hide();
    }

    public async confirmDelete() {
        const self = this;
        this.showPending('DELETE_INVOICE_PENDING');

        const invoices = this.getSelectedInvoices();

        for (let i = 0; i < invoices.length; i++) {
            const index = self.invoices.findIndex(
                (distributedInvoice: any) => distributedInvoice.invoice.invoiceId === invoices[i].invoice.invoiceId,
            );
            const [err] = await to(financeService.deleteInvoice(invoices[i].invoice.invoiceId));
            if (err) {
                this.clearAndShowError('DELETE_INVOICE_FAILED', err);
            }
            self.invoices.splice(index, 1);
        }

        self.$refs.deleteConfirm.hide();
        this.clearAndShowSuccess('DELETE_INVOICE_SUCCESS');
    }

    public openAmortizeModal() {
        this.$refs.amortizeModal.show();
    }

    public openChangeLegalEntityRefModal() {
        this.$refs.legalEntityRef.show();
    }

    public renderDistribution(h, _, row, listeners) {
        const column = this.invoiceColumns[row.columnIndex];
        const split = column.field.split('_');
        let accountType = AccountType.Person;
        if (split[0].indexOf('group') > -1) {
            accountType = AccountType.GroupIncome;
        }

        const distribution = row.dataItem.distributions.find(
            (d) =>
                d.account && d.account.relatedEntityId && d.account.relatedEntityId === parseInt(split[1]) && d.account.accountType === accountType,
        );

        const isNegative = row.dataItem.invoice.amount < 0;
        let amount = distribution.amount;

        if (isNegative && distribution.amount > 0 && row.dataItem.invoice.invoiceType !== InvoiceType.Expenses) {
            amount = 0;
        }

        const props = {
            value: amount,
            prop: column.field,
            isNegative,
            dataItem: row.dataItem,
        };

        return h(Vue.component('grid-numeric-editor'), {
            props,
            on: {
                saved: (a) => {
                    listeners.saved(a);
                },
                changed: (a) => {
                    listeners.changed(a);
                },
            },
        });
    }

    public getOpenInvoiceAmount() {
        let amountNotDistributed = 0;
        this.invoices.forEach((invoice: DistributedInvoice) => {
            let distributedAmount = 0;
            invoice.distributions.forEach((distribution: Distribution) => {
                if (distribution.amount) {
                    distributedAmount += parseFloat(distribution.amount.toString());
                }
            });

            amountNotDistributed += invoice.invoice.amount - distributedAmount;
        });

        return Vue.filter('number-format')(amountNotDistributed);
    }

    public getDistributedInvoiceAmount() {
        let amountDistributed = 0;
        this.invoices.forEach((invoice: DistributedInvoice) => {
            let distributedAmount = 0;
            invoice.distributions.forEach((distribution: Distribution) => {
                if (distribution.amount) {
                    distributedAmount += parseFloat(distribution.amount.toString());
                }
            });

            amountDistributed += distributedAmount;
        });

        return Vue.filter('number-format')(amountDistributed);
    }

    public getData() {
        return this.invoices.slice(this.skip, this.take + this.skip);
    }

    public getTotal() {
        return this.invoices.length;
    }

    public getSelectedInvoices() {
        return this.invoices.filter((item) => item.selected === true);
    }

    get selectedInvoice() {
        // only 1 invoice returned
        return this.invoices.find((item) => item.selected === true);
    }

    public areAllSelected() {
        return this.invoices.findIndex((item) => item.selected === false) === -1;
    }

    public filterInvoices() {
        this.invoices = [];
        this.isReloadingGrid = true;

        let invoices = this.allInvoices;

        if (this.textSearch) {
            invoices = invoices.filter((invoice) => this.filterPropsOnText(invoice, this.textSearch));
        }

        if (this.onlyNotYetDistributed) {
            invoices = invoices.filter((invoice) => invoice.amountLeftFilter !== 0);
        }

        if (this.onlyRecurring) {
            invoices = invoices.filter((invoice) => !invoice.invoice.isRecurring);
        }

        if (this.textSearch || this.onlyNotYetDistributed || this.onlyRecurring) {
            // reset to page 1
            this.skip = 0;
        }

        this.invoices = invoices;
        this.isReloadingGrid = false;
        this.reloadInvoicesGrid++;
    }
}
