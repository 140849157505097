import EnvService from '@/services/EnvService';

const envService = new EnvService();

const EnvPlugin = {
    install(Vue) {
        Vue.$env = () => {
            return envService.getEnviroment();
        };

        Vue.prototype.$env = () => {
            return envService.getEnviroment();
        };
    },
};

export default EnvPlugin;
