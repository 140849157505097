import { render, staticRenderFns } from "./team-finance-settings.vue?vue&type=template&id=2a2b8d98&"
import script from "./team-finance-settings.ts?vue&type=script&lang=js&"
export * from "./team-finance-settings.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports