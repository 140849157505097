import Component from 'vue-class-component';
import BaseGroupPage from './BaseGroupPage';
import { functions } from '../../../../helpers/functions';
import Vue from 'vue';
import { teamHelper, financeService } from '@/main';
import { InvoiceType } from '../../../../models/InvoiceType';
import to from 'await-to-js';
import { enMessages } from '@/language/en';
import { Watch } from 'vue-property-decorator';
import { AccountType } from '@/models/AccountType';

@Component
export default class GroupDetailExpense extends BaseGroupPage {
    public invoiceType: InvoiceType = InvoiceType.Expenses;

    @Watch('onlyRecurring')
    public onRecurringFilterChange() {
        this.filterInvoices();
    }

    @Watch('onlyNotYetDistributed')
    public onFailureFilterChange() {
        this.filterInvoices();
    }

    @Watch('textSearch')
    public onTextFilterChange(val, oldVal) {
        if ((val && val.length > 2) || (oldVal && oldVal.length > val.length)) {
            this.filterInvoices();
        }
    }

    public async mounted() {
        const self = this;

        this.invoiceColumns.push({
            title: 'IS_RECURRING',
            width: 125,
            field: 'invoice.isRecurring',
            locked: true,
            cell: this.renderRecurring,
            headerCell: this.translate,
        });

        await this.initInvoicesOrExpensesGroup(InvoiceType.Expenses);

        self.isLoaded = true;
    }

    public async openModalMultiDistribution() {
        const modal = this.$refs.multiIncomeDistributionModal;
        modal.show();
    }

    public async saveRow(a) {
        const self = this;

        const distributedInvoice = a.dataItem;
        const split = a.prop.split('_');
        let accountType = AccountType.Person;
        if (split[0].indexOf('group') > -1) {
            accountType = AccountType.GroupSavings;
        }

        const distribution = distributedInvoice.distributions.find(
            (d) =>
                d.account && d.account.relatedEntityId && d.account.relatedEntityId === parseInt(split[1]) && d.account.accountType === accountType,
        );
        distribution.amount = a.value;
        const amount = functions.amountLeft(distributedInvoice, distributedInvoice.invoice.amount < 0);
        if ((amount >= 0 && distributedInvoice.invoice.amount > 0) || (distributedInvoice.invoice.amount < 0 && amount <= 0)) {
            self.showPending('DISTRIBUTING_INVOICES_PENDING');
            const [err] = await to(financeService.saveDistribution(distributedInvoice, teamHelper.getTeamId(), this.group.groupId));
            if (err) {
                self.clearAndShowError('DISTRIBUTING_INVOICES_FAILED', err);
            } else {
                self.clearAndShowSuccess('DISTRIBUTING_INVOICES_SUCCESS');

                Vue.set(distributedInvoice, 'inEdit', false);
                Vue.set(distributedInvoice, 'amountLeftFilter', distributedInvoice.amountLeft);

                this.editID = null;

                await this.reloadGrid(InvoiceType.Expenses);
                await this.calculateRevenue();
            }
        } else {
            return self.showError(enMessages.notAllowedToDistribute);
        }
    }

    public renderRecurring(h, _, row) {
        return h('td', { class: 'bg-white' }, [row.dataItem.invoice.isRecurring ? 'Yes' : 'No']);
    }
}
