import { groupService, insightService, resultService } from '@/main';
import GraphData from '@/models/Graph/GraphData';
import GraphNode from '@/models/Graph/Node';
import InsightView from '@/models/Insights/InsightView';
import PeriodRange from '@/models/PeriodRange';
import to from 'await-to-js';
import { Module, Mutation, VuexModule } from 'vuex-class-modules';
import MapData from '../models/MapData';
import store from '@/store/index';
import Group from '@/models/Group';
import InsightTotals from '../models/InsightTotals';
import InsightResponse from '../models/InsightResponse';
import { Member } from '@/models/Member';
import InsightTeamTotals from '../models/InsightTeamTotals';
import RangeItem from '@/models/Finance/Api/RangeItem';
import TeamResultItem from '@/models/Finance/Api/TeamResultItem';
import GroupResultItem from '@/models/Finance/Api/GroupResultItem';
import MapCellData from '../models/MapCellData';
import { CellScore } from '../models/CellScore';
import { CellIndicatorScore } from '../models/CellIndicatorScore';
import { numberFormat } from '@/filters';
import GraphEdge from '@/models/Graph/GraphEdge';

@Module
class InsightStoreModule extends VuexModule {
    private cache: { [key: string]: MapData } = {};

    public async getTeamsInsight(insight: InsightView, dates: PeriodRange, refresh: boolean): Promise<MapData> {
        return this.getCachedOrFetch(insight.insightViewId, 'teams', dates, refresh, async() => {
            return await this.getTeamsData(insight, dates);
        });
    }

    public async getActivitiesInsight(teamId: number, dates: PeriodRange, refresh: boolean): Promise<MapData> {
        return this.getCachedOrFetch(teamId, 'activities', dates, refresh, async() => {
            return await this.getActivitiesData(teamId, dates);
        });
    }

    public async getMembersInsight(activityId: number, dates: PeriodRange, refresh: boolean) {
        return this.getCachedOrFetch(activityId, 'activities', dates, refresh, async() => {
            return await this.getMembersData(activityId, dates.fromFilterMonth);
        });
    }

    private async getTeamsData(insight: InsightView, dates: PeriodRange): Promise<MapData> {
        const [err, response] = await to(insightService.getLens(
            'ecosystem-finance',
            insight.insightViewId,
            dates,
            null,
        ));

        if (err) {
            throw Error('Unable to fetch insight');
        }

        const data = response.data as InsightResponse;
        const { graph, table, totals } = data;
        const mapData = new MapData();
        mapData.edges = (graph as GraphData).edges.map((edge: GraphEdge) => {
            return {
                origin: edge.origin,
                destination: edge.destination,
                label: edge.total,
            };
        });
        mapData.nodes = graph.nodes.map((node: GraphNode) => {
            const nodeTotals = totals.teamTotals.find((t: InsightTeamTotals) => t.teamId === node.id);
            const cellData = this.getEcologyOrTeamTotals(nodeTotals);
            cellData.name = node.name;
            return {
                id: node.id,
                title: node.name,
                data: cellData,
            };
        });
        mapData.external = [
            { id: 'member_cost', title: 'Member costs' },
            { id: 'transfers_outside', title: 'Transfers outside insight' },
            { id: 'activity_expenses', title: 'Activity expenses' },
            { id: 'external_revenue', title: 'External revenue' },
        ];

        totals.teamTotals.forEach((team) => {
            const destinations = {
                'member_cost': this.getTableValue(table, team.teamId, 'memberExpenses') + this.getTableValue(table, team.teamId, 'memberNonRecurringExpenses'),
                'activity_expenses': this.getTableValue(table, team.teamId, 'groupExpenses') + this.getTableValue(table, team.teamId, 'groupNonRecurringExpenses'),
            };

            const origins = {
                'external_revenue': this.getTableValue(table, team.teamId, 'externalRevenue'),
            }

            Object.keys(destinations).forEach((key: string) => {
                if (destinations[key]) {
                    mapData.edges.push({
                        origin: team.teamId,
                        destination: key,
                        label: destinations[key],
                    });
                }
            });

            Object.keys(origins).forEach((key: string) => {
                if (origins[key]) {
                    mapData.edges.push({
                        destination: team.teamId,
                        origin: key,
                        label: origins[key],
                    });
                }
            });
        });
        mapData.total = this.getEcologyOrTeamTotals(totals);
        return mapData;
    }

    // Activities
    private async getActivitiesData(teamId: number, dates: PeriodRange): Promise<MapData> {
        const mapData = new MapData();
        mapData.external = [
            { id: 'member_cost', title: 'Member costs' },
            { id: 'transfers_outside', title: 'Transfers outside insight' },
            { id: 'activity_expenses', title: 'Activity expenses' },
            { id: 'external_revenue', title: 'External revenue' },
        ];

        const results = await resultService.getGroupResults(teamId, dates.fromFilterMonth, dates.toFilterMonth);
        const groupResults: { [key: string]: GroupResultItem[] }  = {};

        results.items.forEach((result: RangeItem<TeamResultItem>) => {
            result.item.groupResults.forEach((groupResult: GroupResultItem) => {
                const id = groupResult.group.groupId;
                if (groupResult.group.deleted) {
                    return;
                }
                if (!groupResults[id]) {
                    groupResults[id] = [];
                }
                groupResults[id].push(groupResult);
            });
        });

        Object.keys(groupResults).forEach((groupId: string) => {
            const agregate = new GroupResultItem();
            agregate.startBalance = null;
            groupResults[groupId].forEach((result: GroupResultItem) => {
                if (!agregate.startBalance === null) {
                    agregate.startBalance = result.startBalance ? result.startBalance : 0;
                }
                agregate.expenses += result.expenses;
                agregate.externalRevenue += result.externalRevenue;
                agregate.result += result.result;
                agregate.balance = result.balance;
                agregate.group = result.group;
            });

            mapData.nodes.push({
                id: groupId,
                title: agregate.group.name,
                data: this.getActivityTotals(agregate),
            });
        });
        return mapData;
    }

    // Member data
    private async getMembersData(activityId: number, period) {
        const response = await groupService.getGroup(activityId, period);
        const group: Group = response.data;
        const mapData = new MapData();
        mapData.nodes = group.members.map((member: Member) => {
            return { id: member.memberId, title: [member.firstName, member.insertion, member.lastName].join(' ') };
        });
        return mapData;
    }

    private getTableValue(table: any, teamId: number, property: string) {
        let total = 0;
        table.results.items.forEach((item) => {
            const teamResult = item.item.teamResults.find((x: any) => x.teamId === teamId);
            total += teamResult[property];
        });
        return total;
    }

    private getEcologyOrTeamTotals(totals: InsightTotals | InsightTeamTotals): MapCellData {
        if (!totals) {
            return new MapCellData();
        }

        const data = new MapCellData();
        if (totals.externalRevenue && totals.result) {
            const percent = totals.result / totals.externalRevenue * 100;
            data.colorScore = percent <= -10 ? CellScore.Bad :
                percent <= 0 ? CellScore.BelowAvarage :
                percent <= 20 ? CellScore.AboveAvarage :
                CellScore.Ambigious;
        }
        data.title = numberFormat(totals.balance);
        data.subtitle = numberFormat(totals.result);
        data.labels = ['Start balance', 'Balance', 'Result', 'Expenses', 'External revenue'];
        data.values = [totals.startBalance, totals.balance, totals.result, totals.expenses, totals.externalRevenue].map((value) => numberFormat(value));
        data.indicatorScore = totals.result > 0 ? CellIndicatorScore.Positive : CellIndicatorScore.Negative;
        return data;
    }

    private getActivityTotals(totals: GroupResultItem): MapCellData {
        const data = new MapCellData();
        if (totals.result && totals.externalRevenue) {
            const percent = totals.result / totals.externalRevenue * 100;
            data.colorScore = percent <= -10 ? CellScore.Bad :
                percent <= 0 ? CellScore.BelowAvarage :
                percent <= 20 ? CellScore.AboveAvarage :
                CellScore.Ambigious;
        }
        data.name = totals.group.name;
        data.title = numberFormat(totals.balance);
        data.subtitle = numberFormat(totals.result);
        data.labels = ['Start balance', 'Balance', 'Result', 'Expenses', 'External revenue'];
        data.values = [totals.startBalance, totals.balance, totals.result, totals.expenses, totals.externalRevenue].map((value) => numberFormat(value));
        data.indicatorScore = totals.result > 0 ? CellIndicatorScore.Positive : CellIndicatorScore.Negative;
        return data;
    }

    private async getCachedOrFetch(id: string | number, entity: string, dates: PeriodRange, refresh: boolean, callback) {
        let mapData = this.getCachedMapData(id, entity, dates);
        if (refresh || !mapData) {
            mapData = await callback();
            this.SET_CACHE({ id, entity, dates, mapData });
        }
        return mapData;
    }

    private getCacheKey(id: string | number, entity: string, dates: PeriodRange) {
        return [
            id,
            entity,
            dates.fromFilterMonth,
            dates.toFilterMonth,
        ].join('_');
    }

    @Mutation
    private SET_CACHE(payload: { id: string | number, entity: string, dates: PeriodRange, mapData: MapData }) {
        this.cache[this.getCacheKey(payload.id, payload.entity, payload.dates)] = payload.mapData;
    }

    private getCachedMapData(id: string | number, entity: string, dates: PeriodRange): MapData {
        if (this.cache[this.getCacheKey(id, entity, dates)]) {
            return this.cache[this.getCacheKey(id, entity, dates)];
        }
        return null;
    }
}

export const insightStoreModule = new InsightStoreModule({ store, name: 'map-store-module' });
