import Relation, { IntellectualPropertyLimitation } from '@/models/Finance/Relation';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RelationSettingsModal from '../modals/relation-settings-modal';

@Component
export default class GridRelationSettingsComponent extends Vue {
    @Prop({ default: () => null }) public relation: Relation;
    @Prop({ default: () => [] }) public intellectualPropertyLimitations: IntellectualPropertyLimitation[];
    public isLoading: boolean = true;
    public $refs!: {
        relationModal: RelationSettingsModal;
    };

    public async mounted() {
        this.isLoading = false;
    }

    public get from() {
        return `${this.relation.originLegalEntityReference ? this.relation.originLegalEntityReference : ''} ${
            this.relation.originTeam ? '(team ' + this.relation.originTeam.name + ')' : ''
        } ${this.relation.originGroup ? '(group ' + this.relation.originGroup.name + ')' : ''}`;
    }

    public get to() {
        return `${this.relation.destinationLegalEntityReference ? this.relation.destinationLegalEntityReference : ''} ${
            this.relation.destinationTeam ? '(team ' + this.relation.destinationTeam.name + ')' : ''
        } ${this.relation.destinationGroup ? '(group ' + this.relation.destinationGroup.name + ')' : ''}`;
    }

    public isSettingInhereted() {
        return (
            this.relation && this.relation.relationSettings.find((x) => x.current) && this.relation.relationSettings.find((x) => x.current).inherited
        );
    }

    public hasActiveSettings() {
        return this.relation && this.relation.relationSettings.find((x) => x.current);
    }

    public openSettingsModal() {
        this.$refs.relationModal.setData(this.relation, this.relation.relationSettings.find((x) => x.current));
        this.$refs.relationModal.show();
    }
}
