import Enviroment from '@/models/Enviroment';

export default class EnvService {
    public getEnviroment(): Enviroment {
        const browserWindow: any = window || {};
        const env = browserWindow.__env || {};

        return new Enviroment(env);
    }
}
