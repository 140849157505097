import FinanceBase from './FinanceBase';
import Compensation from './Compensation';
import DistributedInvoice from './DistributedInvoice';
import Transfer from './Transfer';
import { Distribution } from './Interfaces';
import { AccountType } from './AccountType';
import { teamHelper } from '@/main';
import Vue from 'vue';
import Range from './Finance/Api/Range';
import AccountFunds from './Finance/Api/AccountFunds';

export default class FinanceMe extends FinanceBase {
    public transfers: any;
    public personTransfers: any;
    public groups: any;
    public memberName: string;
    public personId: number;
    public compensations: any;
    public previousInvoices: any;
    public previousExpenses: any;
    public previousExtras: any;
    public totalPreviousIncomes: string;
    public totalPreviousExpenses: string;
    public totalPreviousExtras: string;
    public totalPreviousExternalTransfers: string;
    public totalPreviousResult: string;
    public totalPreviousBudget: string;

    constructor(
        personId: number,
        invoices,
        previousInvoices,
        expenses,
        previousExpenses,
        balance: Range<AccountFunds>,
        externalTransfers,
        prevExternalTransfers,
    ) {
        super(invoices, expenses);

        this.personId = personId;
        this.invoices = this.filterEmptyInvoices(invoices);
        this.expenses = this.filterEmptyInvoices(expenses);
        this.personTransfers = externalTransfers;

        const recurringExpenses = this.expenses.filter((invoice) => {
            return invoice.invoice.isRecurring;
        });
        this.extras = this.expenses.filter((invoice) => {
            return !invoice.invoice.isRecurring;
        });
        this.expenses = recurringExpenses;

        this.previousInvoices = this.filterEmptyInvoices(previousInvoices);
        this.previousExpenses = this.filterEmptyInvoices(previousExpenses);

        const previousRecurringExpenses = this.previousExpenses.filter((invoice) => {
            return invoice.invoice.isRecurring;
        });
        this.previousExtras = this.previousExpenses.filter((invoice) => {
            return !invoice.invoice.isRecurring;
        });
        this.previousExpenses = previousRecurringExpenses;

        const totalIncomes = this.calculatePersonIncome(this.personId, this.invoices);
        const totalPreviousIncomes = this.calculatePersonIncome(this.personId, this.previousInvoices);
        const totalExpenses = this.calculatePersonExpenses(this.personId, this.expenses);
        const totalPreviousExpenses = this.calculatePersonExpenses(this.personId, this.previousExpenses);
        const totalExtras = this.calculatePersonExtras(this.personId, this.extras);
        const totalPreviousExtras = this.calculatePersonExtras(this.personId, this.previousExtras);
        const totalTransfers = this.calculateExternalTransferAmount(externalTransfers);
        const totalPrevTransfers = this.calculateExternalTransferAmount(prevExternalTransfers);

        this.setMeFinanceTotals(
            totalIncomes,
            totalPreviousIncomes,
            totalExpenses,
            totalPreviousExpenses,
            balance,
            totalExtras,
            totalPreviousExtras,
            totalTransfers,
            totalPrevTransfers,
        );
    }

    public loadCompensations(groups, transfers) {
        const compensations = [];
        this.invoices.forEach((invoice: DistributedInvoice) => {
            if (!invoice.invoice.group) {
                const transfer = transfers.find((t: Transfer) => {
                    return t.invoice === invoice.invoice.invoiceId;
                });
                if (transfer) {
                    invoice.invoice.group = transfer.group;
                }
            }
        });

        for (let i = 0; i < groups.length; i++) {
            const compensation = new Compensation();
            compensation.costs = this.calculatePersonExpenses(this.personId, this.expenses);
            compensation.group = groups[i];
            compensation.energyDedication = parseInt(groups[i].energyDedication);

            // select all group income invoices
            const groupInvoices = this.invoices.filter((invoice: DistributedInvoice) => {
                const groupIncomeAccount = invoice.distributions.find((distribution: Distribution) => {
                    if (distribution.account) {
                        return (
                            distribution.account.relatedEntityId === groups[i].groupId && distribution.account.accountType === AccountType.GroupIncome
                        );
                    }
                });
                return invoice.invoice.group === groups[i].groupId || groupIncomeAccount;
            });

            groupInvoices.forEach((invoice) => {
                invoice.distributions.forEach((distribution: Distribution) => {
                    compensation.amounts.push(distribution.amount);
                });
            });

            if ((compensation.amounts && compensation.amounts.length) || compensation.group.energyDedication > 0) {
                compensations.push(compensation);
            }
        }

        this.compensations = compensations;
    }

    public calculateExternalTransferAmount(transfers: any) {
        let amount = 0;
        const team = teamHelper.currentTeamId;
        const teamId = teamHelper.getTeamId(team);

        transfers.forEach((transfer) => {
            if (transfer.senderTeam.teamId === teamId) {
                amount += -1 * transfer.amount;
            } else {
                amount += transfer.amount;
            }
        });

        return amount;
    }

    public setMeFinanceTotals(
        incomes,
        previousIncomes,
        expenses,
        previousExpenses,
        balance: Range<AccountFunds>,
        extras,
        previousExtras,
        transfers,
        prevTransfers,
    ) {
        this.totalIncomes = Vue.filter('number-format')(incomes);
        this.totalPreviousIncomes = Vue.filter('number-format')(previousIncomes);
        this.totalExpenses = Vue.filter('number-format')(expenses);
        this.totalPreviousExpenses = Vue.filter('number-format')(previousExpenses);
        this.totalExtras = Vue.filter('number-format')(extras);
        this.totalPreviousExtras = Vue.filter('number-format')(previousExtras);
        this.totalExternalTransfers = Vue.filter('number-format')(transfers);
        this.totalPreviousExternalTransfers = Vue.filter('number-format')(prevTransfers);

        if (balance) {
            let currentBalance = 0;
            let previousBalance = 0;
            if (balance.items.length === 1) {
                currentBalance = balance.items[balance.items.length - 1].item.balance;
            } else if (balance.items.length > 1) {
                currentBalance = balance.items[balance.items.length - 1].item.balance;
                previousBalance = balance.items[balance.items.length - 2].item.balance;
            }

            this.previousBalance = Vue.filter('number-format')(previousBalance);
            this.currentBalance = Vue.filter('number-format')(currentBalance);
            this.totalResult = Vue.filter('number-format')(currentBalance - previousBalance);
            this.totalPreviousResult = Vue.filter('number-format')(previousIncomes - previousExpenses);
            this.totalBudget = Vue.filter('number-format')(currentBalance);
            this.totalPreviousBudget = Vue.filter('number-format')(previousBalance);
        }
    }
}
